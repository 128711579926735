import {Controller} from '@hotwired/stimulus';
import {NotificationReadEventDetail} from 'app/controllers/notifications-reader-list-item-controller';

export default class NotificationsSubmenuItemController extends Controller
{
    private unreadClass!: string;
    private idValue!: number;

    static classes = ['unread'];
    static values = {id: Number};

    public connect() {
        window.addEventListener('notification:read', (event) => this.handleNotificationRead(event as CustomEvent<NotificationReadEventDetail>));
    }

    private handleNotificationRead(event: CustomEvent<NotificationReadEventDetail>): void {
        if (event.detail.id !== this.idValue) {
            return;
        }
        this.element.classList.remove(this.unreadClass);
    }
}
