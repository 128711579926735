import Component from 'app/components/component';
import highlight from 'highlight.js/lib/core';
import 'highlight.js/styles/vs.css';
import javascript from 'highlight.js/lib/languages/javascript';
import php from 'highlight.js/lib/languages/php';
import json from 'highlight.js/lib/languages/json';
import NotImplementedException from 'app/errors/not-implemented-exception';

highlight.registerLanguage('javascript', javascript);
highlight.registerLanguage('php', php);
highlight.registerLanguage('json', json);

export default class CodeHighlighter extends Component<Config>
{
    protected initialize() {
        let code = this.getCode();
        if (this.config.language === 'json') {
            code = JSON.stringify(JSON.parse(code), null, 4);
        }
        const data = highlight.highlight(code, {language: this.config.language});
        this.$element.empty().html('<pre><code>' + data.value + '</code></pre>');
    }

    public getComponentName(): string {
        return 'CodeHighlighter';
    }

    public getCode(): string {
        const configCode = this.config.code as unknown;
        if (typeof configCode === 'string') {
            return configCode;
        }
        const attributeCode = this.$element.attr('data-code-highlighter-code') as unknown;
        if (typeof attributeCode === 'string') {
            return attributeCode;
        }
        if (typeof configCode === 'object') {
            return JSON.stringify(configCode);
        }
        throw new NotImplementedException();
    }
}

type Config = {
    code: string;
    language: 'javascript' | 'php' | 'json';
}
