import Component from 'app/components/component';

export default class RsFwdManualSignForm extends Component
{
    private uploadSignedDocument: JQuery;
    private signedDocumentContainer: JQuery;

    protected initialize() {
        this.refreshBindings();
    }

    public getComponentName(): string {
        return 'RsFwdManualSignForm';
    }

    public refreshBindings() {
        const changEvent = this.getScopedChangeEvent();
        this.uploadSignedDocument = this.$element.find('[data-rs-fwd-manual-sign-document-form-upload-signed-document]');
        this.signedDocumentContainer = this.$element.find('[data-rs-fwd-manual-sign-document-form-signed-document-container]');
        this.uploadSignedDocument.on(changEvent, () => this.handleUploadSignedDocumentChange());
        this.uploadSignedDocument.trigger(changEvent);
    }

    private handleUploadSignedDocumentChange(): void {
        const isChecked = this.uploadSignedDocument.is(':checked');
        isChecked ? this.signedDocumentContainer.show() : this.signedDocumentContainer.hide();
    }
}
