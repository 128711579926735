import Component, {EmptyConfig} from 'app/components/component';

export default class DiffResult extends Component<EmptyConfig, HTMLDivElement>
{
    private globalToggleBtn: JQuery;
    private nodeToggleButtons: JQuery;

    public getComponentName(): string {
        return 'DiffResult';
    }

    protected initialize() {
        this.refreshBindings();
    }

    public refreshBindings() {
        const clickEvent = this.getScopedClickEvent();
        this.globalToggleBtn = this.$element.find('[data-diff-result-toggle-btn]');
        this.nodeToggleButtons = this.$element.find('[data-diff-result-node-toggle-btn]');
        this.globalToggleBtn.off(clickEvent);
        this.globalToggleBtn.on(clickEvent, () => this.handleGlobalToggleClick());
        this.nodeToggleButtons.off(clickEvent);
        this.nodeToggleButtons.on(clickEvent, (event) => this.handleNodeToggleClick(event as JQuery.ClickEvent));
    }

    private handleGlobalToggleClick() {
        this.isOpened() ? this.close() : this.open();
    }

    public open() {
        this.getAllNodes().forEach(node => node.open());
    }

    public close() {
        this.getAllNodes().forEach(node => node.close());
    }

    public isOpened(): boolean {
        return this.getRootNode().isOpened();
    }

    public isClosed(): boolean {
        return !this.isOpened();
    }

    private getAllNodes(): Node[] {
        const nodes = this.$element.find('[data-diff-result-node]');
        return nodes.map((_, node) => new Node($(node))).toArray();
    }

    private getRootNode(): Node {
        const nodeElement = this.$element.find('[data-diff-result-node]:first');
        return new Node(nodeElement);
    }

    private handleNodeToggleClick(event: JQuery.ClickEvent) {
        const target = $(event.currentTarget);
        const nodeElement = target.closest('[data-diff-result-node]');
        const node = new Node(nodeElement);
        node.toggle();
    }
}

class Node
{
    private readonly _nodeElement: JQuery;
    private _childrenElement: JQuery | null = null;
    private _diffsCollectionElement: JQuery | null = null;
    private _toggleBtn: JQuery | null = null;

    constructor(node: JQuery) {
        this._nodeElement = node;
    }

    public isOpened(): boolean {
        return this.diffsCollectionElement.is(':visible') || this.childrenElement.is(':visible');
    }

    public isClosed(): boolean {
        return !this.isOpened();
    }

    public open(): void {
        this.diffsCollectionElement.slideDown('fast', () => this.correctToggleBtnContent());
        this.childrenElement.slideDown('fast', () => this.correctToggleBtnContent());
    }

    public close(): void {
        this.diffsCollectionElement.slideUp('fast', () => this.correctToggleBtnContent());
        this.childrenElement.slideUp('fast', () => this.correctToggleBtnContent());
    }

    public toggle(): void {
        this.isOpened() ? this.close() : this.open();
    }

    private correctToggleBtnContent(): void {
        const showContent = this.toggleBtn.attr('data-diff-result-node-toggle-btn-show-html') as string;
        const hideContent = this.toggleBtn.attr('data-diff-result-node-toggle-btn-hide-html') as string;
        this.isOpened() ? this.toggleBtn.html(hideContent) : this.toggleBtn.html(showContent);
    }

    public get nodeElement(): JQuery {
        return this._nodeElement;
    }

    public get childrenElement(): JQuery {
        if (this._childrenElement === null) {
            this._childrenElement = this.nodeElement.find('[data-diff-result-node-children]:first');
        }
        return this._childrenElement;
    }

    public get diffsCollectionElement(): JQuery {
        if (this._diffsCollectionElement === null) {
            this._diffsCollectionElement = this.nodeElement.find('[data-diff-result-simple-diffs-collection]:first');
        }
        return this._diffsCollectionElement;
    }

    public get toggleBtn(): JQuery {
        if (this._toggleBtn === null) {
            this._toggleBtn = this.nodeElement.find('[data-diff-result-node-toggle-btn]:first');
        }
        return this._toggleBtn;
    }
}
