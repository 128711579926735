import Component from 'app/components/component';
import InvalidStateException from 'app/errors/invalid-state-exception';

export default class ConfirmUrlAction extends Component<Config>
{
    public static readonly defaultConfig: Partial<Config> = {
        url: null,
    };

    public getComponentName(): string {
        return 'ConfirmUrlAction';
    }

    protected initialize() {
        this.refreshBindings();
    }

    public refreshBindings() {
        const clickEvent = this.getScopedClickEvent();
        this.$element.off(clickEvent);
        this.$element.on(clickEvent, (event) => this.onClick(event as JQuery.ClickEvent));
    }

    private onClick(event: JQuery.ClickEvent): void {
        event.stopPropagation();
        event.preventDefault();
        const url = this.getUrl();
        if (confirm(this.config.message)) {
            window.location.href = url;
            return;
        }
    }

    private getUrl(): string {
        let url = this.config.url;
        if (String.isNullOrWhiteSpace(url)) {
            const href = this.$element.attr('href');
            if (typeof href === 'string' && !String.isNullOrWhiteSpace(href)) {
                url = href;
            }
        }
        if (url === null) {
            throw new InvalidStateException('Could not retrieve URL for confirmation.');
        }
        return url;
    }
}

type Config = {
    url: string | null;
    message: string;
}
