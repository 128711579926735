import Component from 'app/components/component';

export default class AdminNavigation extends Component
{
    public getComponentName(): string {
        return 'FrontendNavigation';
    }

    protected initialize() {
        this.$element.find('[data-collapse-side-nav-btn]').on('click', () => {
            this.$element.toggleClass('admin-layout--side-nav-collapsed');
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            if (!this.$element.hasClass('admin-layout--side-nav-collapsed')) {
                document.cookie = `sidenav=;expires=${ new Date(0).toUTCString()};path=/`;
            } else {
                document.cookie = `sidenav=admin-layout--side-nav-collapsed;expires=${now.toUTCString()};path=/`;
            }
        })
    }
}
