import Component from 'app/components/component';
import InvalidStateException from 'app/errors/invalid-state-exception';
import {logger} from 'app/service/logger';

export default class CopyToClipboardButton extends Component<Config, HTMLButtonElement>
{
    protected initialize() {
        this.$element.on('click', () => this.onClick());
    }

    public getComponentName(): string {
        return 'CopyToClipboardButton';
    }

    private async onClick(): Promise<void> {
        if (typeof this.config.value !== 'string') {
            throw new InvalidStateException('Value is not string.');
        }
        await navigator.clipboard.writeText(this.config.value!);
        logger.log('Value "' + this.config.value! + '" was copied to clipboard');
    }
}

type Config = {
    value?: string,
}
