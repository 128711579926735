class Logger
{
    public log(value: any): void {
        if (APP_CONFIG.common.isDebug) {
            console.log(value);
        }
    }
}

export const logger = new Logger();
