import Component from 'app/components/component';
import requiredAttrInputToggler from 'app/utils/required-attr-input-toggler';
import AsyncForm, {AsyncFormEvents} from 'app/components/async-form';
import {componentsLoader} from 'app/service/components-loader';
import Tab from 'app/components/tab';
import InvalidStateException from 'app/errors/invalid-state-exception';
import UnexpectedValueException from 'app/errors/unexpected-value-exception';
import Values from 'app/utils/values';
import axios, {AxiosResponse} from 'axios';
import {logger} from 'app/service/logger';
import dayjs from 'dayjs';
import ChangeEvent = JQuery.ChangeEvent;
import ClickEvent = JQuery.ClickEvent;

export default class RsFwdForm extends Component<Config, HTMLFormElement>
{
    private readonly asyncForm: AsyncForm | null = null;

    constructor(element: HTMLFormElement, config: Partial<Config>) {
        super(element, config as Config, false);
        if (this.$element.attr('data-async-form') !== undefined) {
            this.asyncForm = AsyncForm.getOne(this.$element) as AsyncForm;
        }
        this.initialize();
    }

    protected initialize() {
        this.bindEvents();
        if (this.asyncForm !== null) {
            this.asyncForm.addEventListener(AsyncFormEvents.FormReplaced, () => {
                this.bindEvents();
                if (this.getCurrentStepLabel() === FormStep.StatutoryRepresentativesData) {
                    this.collectionTabSetLastTabAsActive();
                }
            });
        }
    }

    public getComponentName(): string {
        return 'RsFwdForm';
    }

    protected bindEvents(): void {
        this.bindCheckboxVisibilityTogglers();
        this.bindCollectionTabEvents();
        this.bankAccountsBindEvents();
        this.secondaryEconomicActivityFieldsBindEvents();
        this.basicDataBindEvents();
        this.bindInvestmentQuestionnaireEvents();
        this.bindSupplementaryInformationEvents();
    }

    protected getCurrentStep(): number {
        return parseInt(this.$element.attr('data-rs-fwd-form-current-step') as string);
    }

    protected getCurrentStepLabel(): string {
        return this.$element.attr('data-rs-fwd-form-current-step-label') as string;
    }

    protected getCheckboxVisibilityTogglers(): JQuery {
        return this.$element.findWithSelf('[data-rs-fwd-form-checkbox-visibility-toggler]');
    }

    protected bindCheckboxVisibilityTogglers(): void {
        this.getCheckboxVisibilityTogglers().each((_, element): void => {
            const checkbox = $(element);
            const target = this.getCheckboxVisibilityTogglerTarget(checkbox);
            requiredAttrInputToggler.register(target);
            checkbox.on('change', () => this.onCheckboxVisibilityTogglerChange(checkbox));
            this.onCheckboxVisibilityTogglerChange(checkbox);
        });
    }

    protected getCheckboxVisibilityTogglerTarget(checkbox: JQuery): JQuery {
        return $(checkbox.data('selector'));
    }

    protected onCheckboxVisibilityTogglerChange(checkbox: JQuery): void {
        const target = this.getCheckboxVisibilityTogglerTarget(checkbox);
        if (checkbox.is(':checked')) {
            target.show();
            requiredAttrInputToggler.setAsRequired(target);
            return;
        }
        target.hide();
        requiredAttrInputToggler.setAsNotRequired(target);
    }

    protected bindCollectionTabEvents(): void {
        this.getCollectionTabAddBtn().on('click', () => this.onCollectionTabAddBtnClick());
        this.getCollectionTabItemsFirstNames().on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsLastNames().on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsAcademicDegreePrefixes().on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsAcademicDegreeSuffixes().on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.bindCollectionTabVisibilityTogglers();
        this.bindBirthRegistrationNumberEvents();
        this.bindPoliticallyExposedPersonEvents();
        this.getCollectionTabDeleteBtn().on('click', (event: ClickEvent) => this.onCollectionTabDeleteBtnClick(event));
        this.getCollectionTabItemsIsLoggedUserCheckbox().on('change', () => this.onCollectionTabItemIsLoggedUserChange());
        this.onCollectionTabItemIsLoggedUserChange();
        this.getCollectionTabItemsIsSignatureNeeded().on('change', (event: ChangeEvent) => this.onCollectionTabItemIsSignatureNeededChange(event));
        this.getCollectionTabItemsHasAccountDisposerRights().on('change', (event: ChangeEvent) => this.onCollectionTabItemIsSignatureNeededChange(event));
        this.getCollectionTabItemsIsSignatureNeeded().trigger('change');
    }

    protected collectionTabSetLastTabAsActive(): void {
        const tab = this.getCollectionTab();
        tab.setLastTabItemAsSelected();
    }

    protected bindCollectionTabVisibilityTogglers(subject: JQuery | null = null): void {
        subject = subject === null ? this.getCollectionTabItems() : subject;
        subject.findWithSelf('[data-rs-fwd-form-person-checkbox-visibility-toggler]').each((_, element) => {
            const checkbox = $(element);
            const container = this.getCollectionTabItemContainerForSubject(checkbox);
            const target = container.findWithSelf(checkbox.data('selector'));
            requiredAttrInputToggler.register(target);
            checkbox.on('change', () => {
                if (checkbox.is(':checked')) {
                    target.show();
                    requiredAttrInputToggler.setAsRequired(target);
                    return;
                }
                target.hide();
                requiredAttrInputToggler.setAsNotRequired(target);
            });
            checkbox.trigger('change');
        });
    }

    protected bindBirthRegistrationNumberEvents(): void {
        const changeEvent = this.getScopedChangeEvent();
        this.$element.find('[data-rs-fwd-form-person-nationality]').each((_, element) => {
            const nationality = $(element) as JQuery<HTMLSelectElement>;
            nationality.off(changeEvent);
            nationality.on(changeEvent, () => {
                const alpha2Code = nationality?.val()?.toString().toUpperCase();
                const container = this.getCollectionTabItemContainerForSubject(nationality);
                const birthRegNbr = container.find('[data-rs-fwd-form-person-birth-registration-number]');
                const birthRegNbrLabel = container.find('[data-rs-fwd-form-person-birth-registration-number-label]');
                if (alpha2Code === 'CZ' || alpha2Code === 'SK') {
                    birthRegNbr.attr('required', 'required');
                    birthRegNbrLabel.addClass('required');
                    return;
                }
                birthRegNbr.removeAttr('required');
                birthRegNbrLabel.removeClass('required');
            });
            nationality.trigger(changeEvent);
        });
        this.$element.find('[data-rs-fwd-form-person-birth-registration-number]').each((_, element) => {
            const timeoutKey = 'rsFwdFormPersonBirthRegNbrParseTimeout';
            const birthRegNbr = $(element) as JQuery<HTMLInputElement>;
            birthRegNbr.off(changeEvent);
            birthRegNbr.on(changeEvent, () => {
                let timeout = birthRegNbr.data(timeoutKey);
                if (typeof timeout === 'number') {
                    clearTimeout(timeout);
                }
                const container = this.getCollectionTabItemContainerForSubject(birthRegNbr);
                const birthDate = container.find('[data-rs-fwd-form-person-birth-date]');
                const gender = container.find('[data-rs-fwd-form-person-gender]');
                const canSetInput = (input: JQuery) => {
                    return input.length > 0
                        && typeof input.attr('disabled') === 'undefined'
                        && Values.isEmpty(input.val());
                };
                if (!canSetInput(birthDate) && !canSetInput(gender)) {
                    return;
                }
                if (Values.isEmpty(birthRegNbr.val())) {
                    return;
                }
                timeout = setTimeout(() => {
                    axios.request<any, AxiosResponse<{ date: string, gender?: 'male' | 'female' }>>({
                        url: this.config.birthRegistrationNumberParseUrl,
                        method: 'POST',
                        data: {
                            birthRegistrationNumber: birthRegNbr.val(),
                        },
                        headers: {
                            'X-Requested-With': 'XMLHttpRequest',
                            'Content-Type': 'application/json',
                        },
                    }).then((response) => {
                        try {
                            if (canSetInput(birthDate)) {
                                const date = dayjs(response.data.date, 'YYYY-MM-DD');
                                birthDate.val(date.format('DD.MM.YYYY'));
                            }
                            if (canSetInput(gender) && typeof response.data.gender === 'string') {
                                gender.val(response.data.gender.toUpperCase());
                                gender.trigger('change');
                            }
                        } catch (e) {
                            logger.log(e);
                        }
                    }).catch((error) => {
                        logger.log(error);
                    });
                }, 500);
                birthRegNbr.data(timeoutKey, timeout);
            });
            birthRegNbr.trigger(changeEvent);
        });
    }

    protected getCollectionTabAddBtn(): JQuery {
        return this.$element.findWithSelf('[data-rs-fwd-form-collection-tab-add]');
    }

    protected getCollectionTabDeleteBtn(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-collection-tab-delete-btn]');
    }

    protected getCollectionTabElement(): JQuery {
        return this.$element.findWithSelf('[data-rs-fwd-form-collection-tab]');
    }

    protected getCollectionTabPrototypes(): CollectionTabPrototypes {
        const btn = this.getCollectionTabAddBtn();
        if (btn.length === 0) {
            throw new InvalidStateException('Could not retrieve collection tab prototypes');
        }
        return btn.data('rsFwdFormCollectionTabPrototypes') as CollectionTabPrototypes;
    }

    protected getCollectionTabMessages(): CollectionTabMessages {
        const tabElement = this.getCollectionTabElement();
        if (tabElement.length === 0) {
            throw new InvalidStateException('Could not retrieve collection tab messages');
        }
        return tabElement.data('rsFwdFormCollectionTabMessages') as CollectionTabMessages;
    }

    protected getCollectionTabItemsContainer(): JQuery {
        return this.$element.findWithSelf('[data-rs-fwd-form-collection-tab-items-container]');
    }

    protected getCollectionTabItemsCountElement(): JQuery {
        return this.$element.findWithSelf('[data-rs-fwd-form-collection-tab-items-count]');
    }

    protected getCollectionTabItems(): JQuery {
        const container = this.getCollectionTabItemsContainer();
        return container.findWithSelf('[data-rs-fwd-form-collection-tab-item-container]');
    }

    protected getCollectionTabItemsCount(): number {
        return this.getCollectionTabItems().length;
    }

    protected getCollectionTabItemsFirstNames(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-first-name]');
    }

    protected getCollectionTabItemsLastNames(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-last-name]');
    }

    protected getCollectionTabItemsAcademicDegreePrefixes(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-academic-degree-prefix]');
    }

    protected getCollectionTabItemsAcademicDegreeSuffixes(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-academic-degree-suffix]');
    }

    protected getCollectionTabItemsHasAccountDisposerRights(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-has-account-disposer-rights]');
    }

    protected getCollectionTabItemsIsSignatureNeeded(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-is-signature-needed]');
    }

    protected getCollectionTabItemsIsLoggedUserCheckbox(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-is-logged-user]');
    }

    protected getCollectionTabItemsEmailInput(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-email]');
    }

    protected getCollectionTabItemsEmailInputLabel(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-email-label]');
    }

    protected getCollectionTabItemsPhoneInput(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-phone]');
    }

    protected getCollectionTabItemsPhoneInputLabel(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-phone-label]');
    }

    protected getCollectionTabItemsPhonePrefixSelect(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-phone-prefix]');
    }

    protected getCollectionTabItemsPhonePrefixSelectLabel(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-phone-prefix-label]');
    }

    protected getCollectionTabItemsRegisteredPhoneInput(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-registered-phone]');
    }

    protected getCollectionTabItemsRegisteredPhonePrefixSelect(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-registered-phone-prefix]');
    }

    protected getCollectionTabItemsPreContractDocumentsLanguageContainer(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-pre-contract-documents-language-container]');
    }

    protected getCollectionTabItemsPreContractDocumentsLanguage(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-pre-contract-documents-language]');
    }

    protected getCollectionTabItemsContractDocumentsLanguageContainer(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-contract-documents-language-container]');
    }

    protected getCollectionTabItemsContractDocumentsLanguage(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-contract-documents-language]');
    }

    protected getCollectionTabItemsEmailHelpButton(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-email-help-button]');
    }

    protected getCollectionTabItemsEmailHelpText(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-person-email-help-text]');
    }

    protected getCollectionTab(): Tab {
        const tabEl = this.$element.find('[data-rs-fwd-form-collection-tab]');
        const exception = new InvalidStateException('Collection tab is not initialized.');
        if (tabEl.length === 0) {
            throw exception;
        }
        const tab = Tab.getOne<Tab>(tabEl);
        if (tab === null) {
            throw exception;
        }
        return tab;
    }

    protected getCollectionTabItemContainerForSubject(subject: JQuery): JQuery {
        return subject.closest('[data-rs-fwd-form-collection-tab-item-container]');
    }

    protected getCollectionTabItemPersonData(subject: JQuery): PersonData | null {
        subject = typeof subject.attr('data-rs-fwd-form-collection-tab-item-container') === 'undefined'
            ? this.getCollectionTabItemContainerForSubject(subject)
            : subject;
        if (subject.length === 0) {
            return null;
        }
        const data = subject.data('rsFwdFormPersonData');
        return typeof data === 'object' ? data : null;
    }

    protected onCollectionTabItemNameChange(event: ChangeEvent): void {
        const tabContent = this.getCollectionTabItemContainerForSubject($(event.target as HTMLInputElement));
        const tab = this.getCollectionTab();
        const tabItem = tab.getTabItem(tabContent);
        const firstName = this.getCollectionTabItemsFirstNames(tabContent);
        const lastName = this.getCollectionTabItemsLastNames(tabContent);
        const academicDegreePrefix = this.getCollectionTabItemsAcademicDegreePrefixes(tabContent);
        const academicDegreeSuffix = this.getCollectionTabItemsAcademicDegreeSuffixes(tabContent);
        if (firstName.length === 0 || lastName.length === 0) {
            return;
        }
        if (tabItem === null) {
            throw new InvalidStateException('Could not find tab item for tab content.');
        }
        const messages = this.getCollectionTabMessages();
        let firstNameValue = firstName.val() as string;
        let lastNameValue = lastName.val() as string;
        let value = (firstNameValue + ' ' + lastNameValue).trim();
        const prefix = academicDegreePrefix.val();
        const suffix = academicDegreeSuffix.val();
        if (String.isNullOrWhiteSpace(value)) {
            value = messages.fillFirstNameAndLastName;
        } else {
            if (typeof prefix === 'string' && !String.isNullOrWhiteSpace(prefix)) {
                value = prefix + ' ' + value;
            }
            if (typeof suffix === 'string' && !String.isNullOrWhiteSpace(suffix)) {
                value = value + ', ' + suffix;
            }
        }
        tabItem.find('.tab-header__item-text').html(value);
    }

    protected onCollectionTabItemIsLoggedUserChange(): void {
        const checkboxes = this.getCollectionTabItemsIsLoggedUserCheckbox();
        let checkedCheckbox: JQuery | null = null;
        let notCheckedCheckboxes = $([]);
        checkboxes.each((_, element) => {
            const checkbox = $(element);
            if (checkbox.is(':checked')) {
                checkedCheckbox = checkbox;
                return;
            }
            notCheckedCheckboxes = notCheckedCheckboxes.add(checkbox);
        });
        if (checkedCheckbox === null) {
            notCheckedCheckboxes.prop('disabled', false);
            return;
        }
        notCheckedCheckboxes.prop('disabled', true);
        const user = APP_CONFIG.common.user;
        if (user === null) {
            return;
        }
        const container = this.getCollectionTabItemContainerForSubject(checkedCheckbox);
        const emailInput = this.getCollectionTabItemsEmailInput(container);
        const phoneInput = this.getCollectionTabItemsPhoneInput(container);
        const phonePrefixSelect = this.getCollectionTabItemsPhonePrefixSelect(container);
        const registeredPhoneInput = this.getCollectionTabItemsRegisteredPhoneInput(container);
        const registeredPhonePrefixSelect = this.getCollectionTabItemsRegisteredPhonePrefixSelect(container);
        if (Values.isEmpty(emailInput.val())) {
            emailInput.val(user.email);
        }
        if (Values.isEmpty(phoneInput.val()) && !String.isNullOrWhiteSpace(user.phone)) {
            phoneInput.val(user.phone as string);
            registeredPhoneInput.val(user.phone as string);
        }
        if (!String.isNullOrWhiteSpace(user.phonePrefix)) {
            phonePrefixSelect.val(user.phonePrefix as string);
            phonePrefixSelect.trigger('change');
            registeredPhonePrefixSelect.val(user.phonePrefix as string);
            registeredPhonePrefixSelect.trigger('change');
        }
    }

    protected onCollectionTabItemIsSignatureNeededChange(event: ChangeEvent): void {
        const tabContent = this.getCollectionTabItemContainerForSubject($(event.target as HTMLInputElement));
        const personData = this.getCollectionTabItemPersonData(tabContent);
        const isSignatureNeededCheckbox = this.getCollectionTabItemsIsSignatureNeeded(tabContent);
        const hasAccountDisposerRightsCheckbox = this.getCollectionTabItemsHasAccountDisposerRights(tabContent);
        const emailInput = this.getCollectionTabItemsEmailInput(tabContent);
        const emailLabel = this.getCollectionTabItemsEmailInputLabel(tabContent);
        const phoneInput = this.getCollectionTabItemsPhoneInput(tabContent);
        const phoneInputLabel = this.getCollectionTabItemsPhoneInputLabel(tabContent);
        const phonePrefixSelect = this.getCollectionTabItemsPhonePrefixSelect(tabContent);
        const phonePrefixSelectLabel = this.getCollectionTabItemsPhonePrefixSelectLabel(tabContent);
        const preContractDocumentsLanguageContainer = this.getCollectionTabItemsPreContractDocumentsLanguageContainer(tabContent);
        const preContractDocumentsLanguage = this.getCollectionTabItemsPreContractDocumentsLanguage(tabContent);
        const contractDocumentsLanguageContainer = this.getCollectionTabItemsContractDocumentsLanguageContainer(tabContent);
        const contractDocumentsLanguage = this.getCollectionTabItemsContractDocumentsLanguage(tabContent);
        const emailHelpButton = this.getCollectionTabItemsEmailHelpButton(tabContent);
        const emailHelpText = this.getCollectionTabItemsEmailHelpText(tabContent);
        const messages = this.getCollectionTabMessages();
        if (isSignatureNeededCheckbox.length === 0 && hasAccountDisposerRightsCheckbox.length === 0 && personData === null) {
            return;
        }
        const isSignatureNeeded = isSignatureNeededCheckbox.is(':checked');
        const hasAccountDisposerRights = hasAccountDisposerRightsCheckbox.length > 0
            ? !hasAccountDisposerRightsCheckbox.is(':checked')
            : personData?.hasAccountDisposerRights;
        requiredAttrInputToggler.register(emailInput);
        requiredAttrInputToggler.register(phoneInput);
        requiredAttrInputToggler.register(phonePrefixSelect);
        requiredAttrInputToggler.register(preContractDocumentsLanguage);
        requiredAttrInputToggler.register(contractDocumentsLanguage);
        if (isSignatureNeeded) {
            requiredAttrInputToggler.setAsRequired(preContractDocumentsLanguage);
            requiredAttrInputToggler.setAsRequired(contractDocumentsLanguage);
            preContractDocumentsLanguageContainer.show();
            contractDocumentsLanguageContainer.show();
        } else {
            requiredAttrInputToggler.setAsNotRequired(preContractDocumentsLanguage);
            requiredAttrInputToggler.setAsNotRequired(contractDocumentsLanguage);
            preContractDocumentsLanguageContainer.hide();
            contractDocumentsLanguageContainer.hide();
            preContractDocumentsLanguage.val('').trigger('change');
            contractDocumentsLanguage.val('').trigger('change');
        }
        if (isSignatureNeeded || hasAccountDisposerRights) {
            requiredAttrInputToggler.setAsRequired(emailInput);
            requiredAttrInputToggler.setAsRequired(phoneInput);
            requiredAttrInputToggler.setAsRequired(phonePrefixSelect);
            emailLabel.addClass('required');
            phoneInputLabel.addClass('required');
            phonePrefixSelectLabel.addClass('required');
        } else {
            requiredAttrInputToggler.setAsNotRequired(emailInput);
            requiredAttrInputToggler.setAsNotRequired(phoneInput);
            requiredAttrInputToggler.setAsNotRequired(phonePrefixSelect);
            emailLabel.removeClass('required');
            phoneInputLabel.removeClass('required');
            phonePrefixSelectLabel.removeClass('required');
        }
        emailHelpText.addClass('d-none');
        if (hasAccountDisposerRights) {
            emailHelpButton.show();
            emailLabel.text(messages.registeredEmail)
        } else {
            emailHelpButton.hide();
            emailLabel.text(messages.email);
        }
    }

    protected onCollectionTabAddBtnClick(): void {
        const prototypes = this.getCollectionTabPrototypes();
        const btn = this.getCollectionTabAddBtn();
        const itemIndexKey = 'rsFwdFormCollectionTabMaxItemIndex';
        const lastItemIndex = btn.data(itemIndexKey);
        if (typeof lastItemIndex !== 'number') {
            throw new UnexpectedValueException('Number was expected.');
        }
        btn.data(itemIndexKey, lastItemIndex + 1);
        const itemIndex = (lastItemIndex + 1).toString();
        const itemHtml = String.decodeHtmlEntities(prototypes.item).replace(/__name__/g, itemIndex);
        const itemEl = $(itemHtml);
        const tabItemHtml = String.decodeHtmlEntities(prototypes.tabItem).replace(/__name__/g, itemIndex);
        const tabItem = $(tabItemHtml);
        this.getCollectionTabItemsFirstNames(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsLastNames(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsAcademicDegreePrefixes(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsAcademicDegreeSuffixes(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemNameChange(event));
        this.getCollectionTabItemsIsSignatureNeeded(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemIsSignatureNeededChange(event));
        this.getCollectionTabItemsHasAccountDisposerRights(itemEl).on('change', (event: ChangeEvent) => this.onCollectionTabItemIsSignatureNeededChange(event));
        this.getCollectionTabDeleteBtn(itemEl).on('click', (event: ClickEvent) => this.onCollectionTabDeleteBtnClick(event));
        this.getCollectionTabItemsIsLoggedUserCheckbox(itemEl).on('change', () => this.onCollectionTabItemIsLoggedUserChange());
        this.bindCollectionTabVisibilityTogglers(itemEl);
        componentsLoader.load(itemEl);
        const container = this.getCollectionTabItemsContainer();
        container.append(itemEl);
        const tab = this.getCollectionTab();
        tab.addTabItem(tabItem, tab.tabItems.length - 1);
        tab.setSelectedTabItem(tabItem);
        this.refreshCollectionTabItemsCount();
        this.onCollectionTabItemIsLoggedUserChange();
        this.getCollectionTabItemsIsSignatureNeeded(itemEl).trigger('change');
        this.bindBirthRegistrationNumberEvents();
        this.bindPoliticallyExposedPersonEvents();
    }

    protected refreshCollectionTabItemsCount(): void {
        this.getCollectionTabItemsCountElement().html(this.getCollectionTabItemsCount().toString());
    }

    protected onCollectionTabDeleteBtnClick(event: ClickEvent): void {
        const btn = $(event.target as HTMLButtonElement);
        const tabContent = this.getCollectionTabItemContainerForSubject(btn);
        const tab = this.getCollectionTab();
        const tabItem = tab.getTabItem(tabContent);
        if (tabItem === null) {
            throw new InvalidStateException('Could not find tab item for tab content.');
        }
        tab.removeTabItem(tabItem, true);
        this.refreshCollectionTabItemsCount();
    }

    protected bankAccountsBindEvents(): void {
        const clickEvent = this.getScopedClickEvent();
        const changeEvent = this.getScopedChangeEvent();
        this.bankAccountsGetAddBtn()
            .off(clickEvent)
            .on(clickEvent, () => this.bankAccountsOnAddBtnClick());
        this.bankAccountsGetRemoveBtn()
            .off(clickEvent)
            .on(clickEvent, (event) => this.bankAccountsOnRemoveBtnClick(event as JQuery.ClickEvent));
        this.bankAccountsGetBankCountrySelect()
            .off(changeEvent)
            .on(changeEvent, (event) => this.bankAccountOnBankCountryOrCurrencyChange(event as JQuery.ChangeEvent));
        this.bankAccountsGetBankCurrencySelect()
            .off(changeEvent)
            .on(changeEvent, (event) => this.bankAccountOnBankCountryOrCurrencyChange(event as JQuery.ChangeEvent))
            .trigger(changeEvent);
        this.bankAccountsGetIsDepositCheckbox()
            .off(changeEvent)
            .on(changeEvent, (event) => this.bankAccountsOnIsDepositChange(event as JQuery.ChangeEvent))
            .trigger(changeEvent);
    }

    protected bankAccountsGetItemsContainer(): JQuery {
        return this.$element.find('[data-rs-fwd-form-bank-accounts]');
    }

    protected bankAccountsGetItemContainer(subject: JQuery) {
        return subject.closest('[data-rs-fwd-form-bank-account]');
    }

    protected bankAccountsGetAddBtn(): JQuery {
        return this.$element.find('[data-rs-fwd-form-bank-account-add]');
    }

    protected bankAccountsGetRemoveBtn(): JQuery {
        return this.$element.find('[data-rs-fwd-bank-account-remove]');
    }

    protected bankAccountsGetBankCountrySelect(): JQuery {
        return this.$element.find('[data-rs-fwd-form-bank-account-bank-country]');
    }

    protected bankAccountsGetBankCurrencySelect(): JQuery {
        return this.$element.find('[data-rs-fwd-form-bank-account-currency]');
    }

    protected bankAccountsGetIsDepositCheckbox(): JQuery {
        return this.$element.find('[data-rs-fwd-form-bank-account-is-deposit]');
    }

    protected bankAccountsOnIsDepositChange(event: JQuery.ChangeEvent): void {
        const checkbox = $(event.target as HTMLSelectElement);
        let otherSelectedCheckbox = $([]);
        this.$element.find('[data-rs-fwd-form-bank-account-is-deposit]').each((_, item) => {
            const $item = $(item);
            if (item !== checkbox[0] && $item.is(':checked')) {
                otherSelectedCheckbox = otherSelectedCheckbox.add($item);
            }
        });
        if (checkbox.is(':checked')) {
            otherSelectedCheckbox.prop('checked', false);
            otherSelectedCheckbox.trigger('change');
            return;
        }
    }

    protected bankAccountOnBankCountryOrCurrencyChange(event: JQuery.ChangeEvent): void {
        const sourceElement = $(event.target as HTMLInputElement);
        const container = this.bankAccountsGetItemContainer(sourceElement);
        const iban = container.find('[data-rs-fwd-form-bank-account-iban]');
        const ibanContainer = container.find('[data-rs-fwd-form-bank-account-iban-container]');
        const accountNumber = container.find('[data-rs-fwd-form-bank-account-account-number]');
        const accountNumberContainer = container.find('[data-rs-fwd-form-bank-account-account-number-container]');
        const bankDataContainer = container.find('[data-rs-fwd-form-bank-account-bank-data-container]');
        const currencySelect = container.find('[data-rs-fwd-form-bank-account-currency]');
        const countrySelect = container.find('[data-rs-fwd-form-bank-account-bank-country]');
        requiredAttrInputToggler.register(ibanContainer);
        requiredAttrInputToggler.register(accountNumberContainer);
        requiredAttrInputToggler.register(bankDataContainer);
        const ibanCountries = this.bankAccountGetIbanCountries();
        const country = countrySelect.val() as string;
        const currency = currencySelect.val() as string;
        if ((country === 'CZ' && currency === 'CZK') || ibanCountries.indexOf(country) < 0) {
            accountNumberContainer.show();
            requiredAttrInputToggler.setAsRequired(accountNumberContainer);
            iban.val('');
            ibanContainer.hide();
            requiredAttrInputToggler.setAsNotRequired(ibanContainer);
        } else {
            ibanContainer.show();
            requiredAttrInputToggler.setAsRequired(ibanContainer);
            accountNumber.val('');
            accountNumberContainer.hide();
            requiredAttrInputToggler.setAsNotRequired(accountNumberContainer);
        }
        if (country === 'CZ') {
            bankDataContainer.hide();
            requiredAttrInputToggler.setAsNotRequired(bankDataContainer);
        } else {
            bankDataContainer.show();
            requiredAttrInputToggler.setAsRequired(bankDataContainer);
        }
    }

    protected bankAccountGetIbanCountries(): string[] {
        const countries = this.$element.data('rsFwdFormIbanCountries');
        return Array.isArray(countries) ? countries : [];
    }

    protected bankAccountsOnAddBtnClick(): void {
        const addBtn = this.bankAccountsGetAddBtn();
        const prototype = addBtn.data('rsFwdFormBankAccountPrototype') as string;
        const itemIndexKey = 'rsFwdFormBankAccountItemIndex';
        if (typeof addBtn.data(itemIndexKey) === 'undefined') {
            addBtn.data(itemIndexKey, addBtn.data('rsFwdFormBankAccountMaxItemIndex'));
        }
        addBtn.data(itemIndexKey, addBtn.data(itemIndexKey) as number + 1);
        const itemIndex = addBtn.data(itemIndexKey).toString();
        const html = prototype.replace(/__name__/g, itemIndex);
        const widget = $(html);
        componentsLoader.load(widget);
        this.bankAccountsGetItemsContainer().append(widget);
        this.bankAccountsBindEvents();
    }

    protected bankAccountsOnRemoveBtnClick(event: ClickEvent): void {
        const removeBtn = $(event.target as HTMLButtonElement);
        const container = this.bankAccountsGetItemContainer(removeBtn);
        container.remove();
    }

    protected secondaryEconomicActivityFieldsBindEvents(): void {
        this.secondaryEconomicActivityFieldsGetAddBtn().on('click', () => this.secondaryEconomicActivityFieldsOnAddBtnClick());
        this.secondaryEconomicActivityFieldsGetRemoveBtn().on('click', (event) => this.secondaryEconomicActivityFieldsOnRemoveBtnClick(event));
    }

    protected secondaryEconomicActivityFieldsGetAddBtn(): JQuery {
        return this.$element.find('[data-rs-fwd-form-secondary-economic-activity-fields-add]');
    }

    protected secondaryEconomicActivityFieldsGetRemoveBtn(subject: JQuery | null = null): JQuery {
        subject = subject === null ? this.$element : subject;
        return subject.findWithSelf('[data-rs-fwd-form-secondary-economic-activity-field-remove-btn]');
    }

    protected secondaryEconomicActivityFieldsGetItemsContainer(): JQuery {
        return this.$element.find('[data-rs-fwd-form-secondary-economic-activity-fields]');
    }

    protected secondaryEconomicActivityFieldsGetItemContainer(subject: JQuery): JQuery {
        return subject.closest('[data-rs-fwd-form-secondary-economic-activity-field]');
    }

    protected secondaryEconomicActivityFieldsOnAddBtnClick(): void {
        const addBtn = this.secondaryEconomicActivityFieldsGetAddBtn();
        const prototype = addBtn.data('rsFwdFormSecondaryEconomicActivityFieldsPrototype') as string;
        const itemIndexKey = 'rsFwdFormSecondaryEconomicActivityFieldsItemIndex';
        if (typeof addBtn.data(itemIndexKey) === 'undefined') {
            addBtn.data(itemIndexKey, addBtn.data('rsFwdFormSecondaryEconomicActivityFieldsMaxItemIndex'));
        }
        addBtn.data(itemIndexKey, addBtn.data(itemIndexKey) as number + 1);
        const itemIndex = addBtn.data(itemIndexKey).toString();
        const prototypeName = addBtn.data('rsFwdFormSecondaryEconomicActivityFieldsPrototypeName') as string;
        const html = prototype.replace(new RegExp(prototypeName, 'g'), itemIndex);
        const widget = $(html);
        componentsLoader.load(widget);
        this.secondaryEconomicActivityFieldsGetRemoveBtn(widget).on('click', (event) => this.secondaryEconomicActivityFieldsOnRemoveBtnClick(event));
        this.secondaryEconomicActivityFieldsGetItemsContainer().append(widget);
    }

    protected secondaryEconomicActivityFieldsOnRemoveBtnClick(event: ClickEvent): void {
        const removeBtn = $(event.target as HTMLButtonElement);
        const container = this.secondaryEconomicActivityFieldsGetItemContainer(removeBtn);
        container.remove();
    }

    protected basicDataBindEvents(): void {
        if (this.getCurrentStepLabel() !== FormStep.BasicData) {
            return;
        }
        requiredAttrInputToggler.register(this.basicDataGetClientTypeContainer());
        requiredAttrInputToggler.register(this.basicDataGetIcoContainer());
        this.basicDataSetupForm();
        const changeEvent = this.getScopedChangeEvent();
        const residenceCountry = this.basicDataGetResidenceCountry();
        const clientType = this.basicDataGetClientType();
        residenceCountry.off(changeEvent);
        residenceCountry.on(changeEvent, () => this.basicDataSetupForm());
        clientType.off(changeEvent);
        clientType.on(changeEvent, () => this.basicDataSetupForm());
    }

    protected basicDataGetInfoText(): JQuery {
        return this.$element.find('[data-rs-fwd-basic-data-info-text]');
    }

    protected basicDataGetClientTypeContainer(): JQuery {
        return this.$element.find('[data-rs-fwd-client-type-container]');
    }

    protected basicDataGetIcoContainer(): JQuery<HTMLInputElement> {
        return this.$element.find('[ data-rs-fwd-ico-container]') as JQuery<HTMLInputElement>;
    }

    protected basicDataGetSubmitContainer(): JQuery {
        return this.$element.find('[data-rs-fwd-submit-container]');
    }

    protected basicDataGetResidenceCountry(): JQuery<HTMLSelectElement> {
        return this.$element.find('[data-rs-fwd-residence-country]') as JQuery<HTMLSelectElement>;
    }

    protected basicDataGetClientType(): JQuery<HTMLSelectElement> {
        return this.$element.find('[data-rs-fwd-client-type]') as JQuery<HTMLSelectElement>;
    }

    protected basicDataGetIco(): JQuery {
        return this.$element.find('[data-rs-fwd-ico]');
    }

    protected basicDataSetupForm(): void {
        const residenceCountryVal = this.basicDataGetResidenceCountry().val()?.toString().toLowerCase() as string;
        const clientType = this.basicDataGetClientType();
        const clientTypeOptions = clientType.find('option');
        const clientTypeContainer = this.basicDataGetClientTypeContainer();
        const ico = this.basicDataGetIco();
        const icoContainer = this.basicDataGetIcoContainer();
        const submitContainer = this.basicDataGetSubmitContainer();
        const infoText = this.basicDataGetInfoText();
        const infoTextAlt1 = infoText.attr('data-rs-fwd-basic-data-info-text-alt-1') as string;
        const infoTextAlt2 = infoText.attr('data-rs-fwd-basic-data-info-text-alt-2') as string;
        const clientTypeVal = clientType.val()?.toString().toLowerCase() as string;
        if (Values.isEmpty(residenceCountryVal)) {
            clientType.val('');
            ico.val('');
            infoText.text(infoTextAlt1);
            requiredAttrInputToggler.setAsNotRequired(clientTypeContainer);
            requiredAttrInputToggler.setAsNotRequired(icoContainer);
            clientTypeContainer.hide();
            icoContainer.hide();
            submitContainer.hide();
            return;
        }
        let resetClientType = false;
        clientTypeOptions.each((_, option) => {
            const $option = $(option);
            const availableInCountries = $option.data('rsFwdAvailableInCountries') as string[] | undefined;
            if (!Array.isArray(availableInCountries)) {
                return;
            }
            const residenceCountry = residenceCountryVal.toUpperCase();
            const contains = availableInCountries.indexOf(residenceCountry) >= 0;
            const isDisabled = option.hasAttribute('disabled');
            if (contains && isDisabled) {
                $option.removeAttr('disabled');
                resetClientType = true;
            } else if (!contains && !isDisabled) {
                $option.attr('disabled', 'disabled');
                resetClientType = true;
            }
        });
        if (resetClientType) {
            clientType.val('');
            clientType.trigger('change');
            return;
        }
        clientTypeContainer.show();
        requiredAttrInputToggler.setAsRequired(clientTypeContainer);
        infoText.text(infoTextAlt2);
        if (Values.isEmpty(clientTypeVal)) {
            ico.val('');
            requiredAttrInputToggler.setAsNotRequired(icoContainer);
            icoContainer.hide();
            submitContainer.hide();
            return;
        }
        submitContainer.show();
        // 1 - LLC, 2 - PLC, 3 - Natural person, 4 - Natural person entrepreneur, 5 - Different juridical person
        if (['1', '2', '4', '5'].indexOf(clientTypeVal) >= 0) {
            requiredAttrInputToggler.setAsRequired(icoContainer);
            icoContainer.show();
        } else {
            ico.val('');
            requiredAttrInputToggler.setAsNotRequired(icoContainer);
            icoContainer.hide();
        }
    }

    protected bindPoliticallyExposedPersonEvents(): void {
        const changeEvent = this.getScopedChangeEvent();
        this.$element.find('[data-rs-fwd-form-person-is-politically-exposed-person]').each((_, element) => {
            const isPoliticallyExposedPerson = $(element) as JQuery<HTMLInputElement>;
            isPoliticallyExposedPerson.off(changeEvent);
            isPoliticallyExposedPerson.on(changeEvent, () => {
                // radio works slightly different from checkbox
                if (!isPoliticallyExposedPerson.is(':checked')) {
                    return;
                }
                const isSelected = isPoliticallyExposedPerson.val() === '1';
                const container = this.getCollectionTabItemContainerForSubject(isPoliticallyExposedPerson);
                const politicallyExposedPersonFunctionContainer = container.find('[data-rs-fwd-form-person-politically-exposed-person-function-container]');
                const politicallyExposedPersonFunction = container.find('[data-rs-fwd-form-person-politically-exposed-person-function]');
                requiredAttrInputToggler.register(politicallyExposedPersonFunctionContainer);
                if (isSelected) {
                    politicallyExposedPersonFunctionContainer.show();
                    requiredAttrInputToggler.setAsRequired(politicallyExposedPersonFunctionContainer);
                    return;
                }
                politicallyExposedPersonFunction.val('');
                politicallyExposedPersonFunction.trigger('change');
                politicallyExposedPersonFunctionContainer.hide();
                requiredAttrInputToggler.setAsNotRequired(politicallyExposedPersonFunctionContainer);
            });
            isPoliticallyExposedPerson.trigger(changeEvent);
        });
    }

    protected bindInvestmentQuestionnaireEvents(): void {
        const changeEvent = this.getScopedChangeEvent();
        const hasQuestionnaireRadios = this.$element.find('[data-rs-fwd-form-has-investment-questionnaire]');
        hasQuestionnaireRadios.off(changeEvent);
        hasQuestionnaireRadios.on(changeEvent, (event) => {
            const radio = $(event.target);
            if (!radio.is(':checked')) {
                return;
            }
            const isSelected = radio.val() === '1';
            const questionnaire = this.$element.find('[data-rs-fwd-form-investment-questionnaire]');
            requiredAttrInputToggler.register(questionnaire);
            if (isSelected) {
                questionnaire.show();
                requiredAttrInputToggler.setAsRequired(questionnaire);
                return;
            }
            questionnaire.hide();
            requiredAttrInputToggler.setAsNotRequired(questionnaire);
        });
        hasQuestionnaireRadios.trigger(changeEvent);
    }

    protected bindSupplementaryInformationEvents(): void {
        const changeEvent = this.getScopedChangeEvent();
        const hasCompanyBranchesAndParentCompanyCountriesRadioButton = this.$element.find('[data-rs-fwd-has-company-branches-and-parent-company-countries]');
        const companyBranchesAndParentCompanyCountriesContainer = this.$element.find('[data-rs-fwd-company-branches-and-parent-company-countries-container]');
        const companyBranchesAndParentCompanyCountriesSelect = this.$element.find('[data-rs-fwd-company-branches-and-parent-company-countries]');
        requiredAttrInputToggler.register(companyBranchesAndParentCompanyCountriesContainer);
        const hideCompanyBranchesAndParentCompanyCountriesContainer = () => {
            companyBranchesAndParentCompanyCountriesContainer.hide();
            requiredAttrInputToggler.setAsNotRequired(companyBranchesAndParentCompanyCountriesContainer);
            companyBranchesAndParentCompanyCountriesSelect.val('');
            companyBranchesAndParentCompanyCountriesSelect.trigger('change');
        };
        const showCompanyBranchesAndParentCompanyCountriesContainer = () => {
            companyBranchesAndParentCompanyCountriesContainer.show();
            requiredAttrInputToggler.setAsRequired(companyBranchesAndParentCompanyCountriesContainer);
        };
        hasCompanyBranchesAndParentCompanyCountriesRadioButton.off(changeEvent);
        hasCompanyBranchesAndParentCompanyCountriesRadioButton.on(changeEvent, (event) => {
            const radio = $(event.target);
            if (!radio.is(':checked')) {
                return;
            }
            const isSelected = radio.val() === '1';
            if (isSelected) {
                showCompanyBranchesAndParentCompanyCountriesContainer();
                return;
            }
            hideCompanyBranchesAndParentCompanyCountriesContainer();
        });
        hasCompanyBranchesAndParentCompanyCountriesRadioButton.trigger(changeEvent);
        let isRadioChecked = false;
        for (let i = 0; i < hasCompanyBranchesAndParentCompanyCountriesRadioButton.length; i++) {
            const radio = $(hasCompanyBranchesAndParentCompanyCountriesRadioButton[i]);
            if (radio.is(':checked')) {
                isRadioChecked = true;
                break;
            }
        }
        if (!isRadioChecked) {
            hideCompanyBranchesAndParentCompanyCountriesContainer();
        }
    }
}

type Config = {
    currentStep: number;
    birthRegistrationNumberParseUrl: string;
};

type CollectionTabPrototypes = {
    item: string;
    tabItem: string
};

type CollectionTabMessages = {
    fillFirstNameAndLastName: string;
    email: string;
    registeredEmail: string;
}

type PersonData = {
    hasAccountDisposerRights: boolean;
}

enum FormStep
{
    BasicData = 'basic_data',
    NonStandardClient = 'non_standard_client',
    EconomicEntityData = 'economic_entity_data',
    StatutoryRepresentativesData = 'statutory_representatives_data',
    OwnershipStructure = 'ownership_structure',
    AccountDisposersData = 'account_disposers_data',
    BankAccountsAndSsi = 'bank_accounts_and_ssi',
    FxQuestionnaire = 'fx_questionnaire',
    InvestmentQuestionnaire = 'investment_questionnaire',
    SupplementaryInformation = 'supplementary_information',
    DocumentationOverview = 'documentation_overview',
    Signatures = 'signatures',
    State = 'state',
}
