import Component from 'app/components/component';
import InvalidStateException from 'app/errors/invalid-state-exception';
import scrollIntoViewObserver from 'app/service/scroll-into-view-observer';
import Loader from 'app/utils/loader';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {handleAxiosError} from 'app/utils/axios';
import {componentsLoader} from 'app/service/components-loader';

export enum LoadTrigger
{
    ScrollIntoView = 0,
    Click = 1,
    Immediately = 2,
}

export enum LoadAnimation
{
    None = 0,
    Element = 1,
    ClickedElement = 2,
    CustomElement = 3
}

export default class AsyncContent extends Component<Config>
{
    private isLoaded: boolean = false;
    private isLoading: boolean = false;

    public static readonly defaultConfig: Partial<Config> = {
        loadTrigger: LoadTrigger.ScrollIntoView,
        loadAnimation: LoadAnimation.None,
        loadAnimationElement: null,
        loadClickTriggerElement: null,
    };

    public getComponentName(): string {
        return 'AsyncContent';
    }

    protected async initialize() {
        this.assertConfig();
        const loadTrigger = this.config.loadTrigger;
        if (loadTrigger === LoadTrigger.Immediately) {
            await this.loadContent();
        } else if (loadTrigger === LoadTrigger.Click) {
            this.getLoadClickTriggerElement()?.on('click', async () => await this.loadContent());
        } else if (loadTrigger === LoadTrigger.ScrollIntoView) {
            scrollIntoViewObserver.observe(this.element, async () => {
                scrollIntoViewObserver.unobserve(this.element);
                await this.loadContent();
            });
        }
    }

    private assertConfig(): void {
        const clickEl = this.getLoadClickTriggerElement();
        const animationEl = this.getLoadAnimationElement();
        const loadTrigger = this.config.loadTrigger;
        const loadAnimation = this.config.loadAnimation;
        if (loadTrigger === LoadTrigger.Click && clickEl === null) {
            throw new InvalidStateException('Config "loadClickTriggerElement" cannot be NULL in click trigger mode.');
        }
        if (loadAnimation === LoadAnimation.ClickedElement && loadTrigger !== LoadTrigger.Click) {
            throw new InvalidStateException('Invalid combination of animation mode and trigger mode.');
        }
        if (loadAnimation === LoadAnimation.CustomElement && animationEl === null) {
            throw new InvalidStateException('Config "loadAnimationElement" cannot be NULL in custom animation mode.');
        }
    }

    private getLoadClickTriggerElement(): JQuery | null {
        return this.config.loadTrigger !== LoadTrigger.Click
            ? null
            : this.transformToJqueryOrNull(this.config.loadClickTriggerElement);
    }

    private getLoadAnimationElement(): JQuery | null {
        if (this.config.loadAnimation === LoadAnimation.Element) {
            return this.$element;
        }
        if (this.config.loadAnimation === LoadAnimation.None) {
            return null;
        }
        if (this.config.loadAnimation === LoadAnimation.ClickedElement) {
            return this.getLoadClickTriggerElement();
        }
        return this.transformToJqueryOrNull(this.config.loadAnimationElement);
    }

    private transformToJqueryOrNull(subject: HTMLDivElement | JQuery | string | null): JQuery | null {
        if (subject === null) {
            return null;
        }
        if (subject instanceof HTMLElement) {
            return $(subject);
        }
        if (typeof subject === 'string') {
            const element = $(subject);
            return element.length === 0 ? null : element;
        }
        return subject;
    }

    private turnLoadingOn(): void {
        this.isLoading = true;
        const loadingElement = this.getLoadAnimationElement();
        if (this.config.loadAnimation === LoadAnimation.None || loadingElement === null) {
            return;
        }
        if (this.config.loadAnimation === LoadAnimation.CustomElement) {
            loadingElement.show();
            return;
        }
        Loader.showOverElement(loadingElement);
    }

    private turnLoadingOff(): void {
        this.isLoading = false;
        const loadingElement = this.getLoadAnimationElement();
        if (this.config.loadAnimation === LoadAnimation.None || loadingElement === null) {
            return;
        }
        if (this.config.loadAnimation === LoadAnimation.CustomElement) {
            loadingElement.hide();
            return;
        }
        Loader.hideOverElement(loadingElement);
    }

    private async loadContent(): Promise<void> {
        if (this.isLoaded || this.isLoading) {
            return;
        }
        this.turnLoadingOn();
        try {
            const response = await axios.request<null, AxiosResponse<Response>>({
                method: 'GET',
                url: this.config.url,
            });
            this.turnLoadingOff();
            const content = $(response.data.html);
            componentsLoader.load(content);
            this.$element.empty();
            this.$element.append(content);
            this.isLoaded = true;
            this.triggerEvent(AsyncContentEvents.Loaded)
        } catch (error) {
            this.turnLoadingOff();
            handleAxiosError(error as AxiosError);
        }
    }
}

export enum AsyncContentEvents {
    Loaded = 'app.async-content.loaded',
}

type Response = {
    html: string;
}

type Config = {
    url: string;
    loadTrigger: LoadTrigger;
    loadAnimation: LoadAnimation;
    loadAnimationElement: HTMLDivElement | JQuery | string | null;
    loadClickTriggerElement: HTMLDivElement | JQuery | string | null;
}
