import Component from 'app/components/component';

export default class Dropdown extends Component
{
    private isOpenedClass: string = 'dropdown--is-opened';

    protected initialize() {
        this.getOpener().on('click', () => this.onOpenerClick());
    }

    private onOpenerClick(): void {
        this.$element.toggleClass(this.isOpenedClass);
    }

    private getOpener(): JQuery {
        return this.$element.find('[data-dropdown-opener]');
    }

    public isOpened(): boolean {
        return this.$element.hasClass(this.isOpenedClass);
    }

    public getComponentName(): string {
        return 'Dropdown';
    }
}
