import Component from 'app/components/component';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import QueryString from 'qs';
import Random from 'app/utils/random';
import {logger} from 'app/service/logger';
import $ from 'jquery';
import {componentsLoader} from 'app/service/components-loader';

export default class Datagrid extends Component<Config, HTMLDivElement>
{
    private limit: number;
    private page: number;
    private orderByProperty: string | null = null;
    private orderByDirection: string | null = null;
    private loading: boolean = false;
    private lastProcessId: number | null = null;
    private readonly initialState: { [key: string]: any };
    private filter: Filter;

    public static readonly defaultConfig: Config = {
        modifyHistory: true,
    };

    constructor(element: HTMLDivElement, config: Config | null = null) {
        super(element, config, false);
        this.limit = this.config.limit!;
        this.page = this.config.page!;
        this.initialState = this.snapshotCurrentState();
        this.initialize();
        this.filter = new Filter(this);
        new Pagination(this);
        new Sorting(this);
        CollapsedActions.initializeAll(this);
        this.initialize();
    }

    protected initialize(): void {
        if (this.config.dataTransportMethod === 'GET' && this.config.modifyHistory === true) {
            window.addEventListener('popstate', (event) => this.onHistoryPopstate(event));
        }
    }

    public getComponentName(): string {
        return 'Datagrid';
    }

    private reinitialize(): void {
        this.filter = new Filter(this);
        new Pagination(this);
        new Sorting(this);
        CollapsedActions.initializeAll(this);
    }

    private destroyWidgets(): void {
        CollapsedActions.destroyInitializedItems();
    }

    public reload(): void {
        this.loadItems();
    }

    public navigateToPage(page: number): void {
        this.page = page;
        this.loadItems();
    }

    public changeLimit(limit: number): void {
        this.page = 1;
        this.limit = limit;
        this.loadItems();
    }

    public changeSortBy(sortByProperty: string | null, sortByDirection: string | null = 'ASC'): void {
        if (sortByProperty === null || sortByDirection === null) {
            this.orderByDirection = null;
            this.orderByProperty = null;
            this.loadItems();
            return;
        }
        this.orderByProperty = sortByProperty;
        this.orderByDirection = sortByDirection;
        this.loadItems();
    }

    private showTableMessage(message: string): void {
        const tableBody = this.element.querySelector<HTMLElement>('tbody')!;
        const row = document.createElement('tr');
        row.classList.add('datagrid__table-message-row');
        const cell = document.createElement('td');
        cell.classList.add('datagrid__table-message-cell');
        tableBody.innerHTML = '';
        tableBody.appendChild(row);
        row.appendChild(cell);
        cell.innerText = message;
        cell.setAttribute('colspan', '100%');
    }

    private toggleLoading(): void {
        this.loading = !this.loading;
        if (this.loading) {
            this.showTableMessage(this.config.messages!.loading!);
        }
    }

    private getBaseUrl(): string {
        if (typeof this.config.url === 'string') {
            return this.config.url;
        }
        return location.href.split('?')[0];
    }

    private loadItems(config: AxiosRequestConfig | null = null, modifyHistory: boolean = true): void {
        if (!this.loading) {
            this.toggleLoading();
        }
        config = config === null ? this.buildRequestConfig() : config;
        const processId = Random.integer();
        this.lastProcessId = processId;
        axios
            .request<any, AxiosResponse<Response>>(config)
            .then((response) => {
                if (processId !== this.lastProcessId) {
                    return;
                }
                this.lastProcessId = null;
                this.toggleLoading();
                if (this.config.modifyHistory === true && this.config.dataTransportMethod === 'GET' && modifyHistory) {
                    this.modifyHistory(config!);
                }
                this.replaceSnippets(response.data.snippets);
                this.reinitialize();
            })
            .catch((error) => {
                if (processId !== this.lastProcessId) {
                    return;
                }
                this.lastProcessId = null;
                this.toggleLoading();
                logger.log(error);
                this.showTableMessage(this.config.messages!.error!);
            });
    }

    private buildRequestConfig(): AxiosRequestConfig {
        const config: AxiosRequestConfig = {
            method: this.config.dataTransportMethod,
            url: this.getBaseUrl(),
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
        };
        const paramKey = this.config.dataTransportMethod === 'GET' ? 'params' : 'data';
        config[paramKey] = {};
        config[paramKey][this.config.pageParamName!] = this.page;
        config[paramKey][this.config.limitParamName!] = this.limit;
        if (this.orderByProperty !== null) {
            config[paramKey][this.config.orderByPropertyParamName!] = this.orderByProperty;
        }
        if (this.orderByDirection !== null) {
            config[paramKey][this.config.orderByDirectionParamName!] = this.orderByDirection;
        }
        const filterData = this.filter.getSerializedData();
        if (!$.isEmptyObject(filterData)) {
            for (let property in filterData) {
                if (!Object.prototype.hasOwnProperty.call(filterData, property)) {
                    continue;
                }
                if (typeof config[paramKey][property] === 'undefined') {
                    config[paramKey][property] = filterData[property];
                }
            }
        }
        return config;
    }

    private onHistoryPopstate(event: PopStateEvent): void {
        const state = event.state === null ? this.initialState : event.state;
        const config = this.buildRequestConfig();
        config.params = state;
        this.loadItems(config, false);
    }

    private snapshotCurrentState(overrideByComponentState: boolean = false): { [key: string]: any } {
        const currentState = window.location.search.length > 1
            ? QueryString.parse(window.location.search.substring(1), {depth: 0})
            : {};
        if (typeof currentState[this.config.pageParamName!] === 'undefined' || overrideByComponentState) {
            currentState[this.config.pageParamName!] = this.page.toString();
        }
        if (typeof currentState[this.config.limitParamName!] === 'undefined' || overrideByComponentState) {
            currentState[this.config.limitParamName!] = this.limit.toString();
        }
        if (typeof currentState[this.config.orderByPropertyParamName!] === 'undefined' || overrideByComponentState) {
            currentState[this.config.orderByPropertyParamName!] = this.orderByProperty ?? '';
        }
        if (typeof currentState[this.config.orderByDirectionParamName!] === 'undefined' || overrideByComponentState) {
            currentState[this.config.orderByDirectionParamName!] = this.orderByDirection ?? '';
        }
        return currentState;
    }

    private modifyHistory(config: AxiosRequestConfig): void {
        const currentState = this.snapshotCurrentState(true);
        const data = config.params;
        const merged: any = {};
        const query: any = {};
        for (const property in data) {
            if (!Object.prototype.hasOwnProperty.call(data, property)) {
                continue;
            }
            merged[property] = data[property];
        }
        const filterFormName = this.filter.getName();
        for (const property in currentState) {
            if (!Object.prototype.hasOwnProperty.call(currentState, property)) {
                continue;
            }
            if (filterFormName !== null && property.startsWith(filterFormName)) {
                continue;
            }
            if (typeof merged[property] === 'undefined') {
                merged[property] = currentState[property];
            }
        }
        const mergedProps = Object.getOwnPropertyNames(merged);
        const regex = /([\w\W]+)\[(\d+|)]$/;
        for (const property of mergedProps) {
            if (!Object.prototype.hasOwnProperty.call(merged, property)) {
                continue;
            }
            let value = merged[property];
            if (regex.test(property)) {
                const arrayProp = property.replace(regex, '$1').trim();
                const arrayBracketsProp = arrayProp + '[]';
                if (!Array.isArray(merged[arrayBracketsProp])) {
                    merged[arrayBracketsProp] = [];
                }
                if (!Array.isArray(query[arrayProp])) {
                    query[arrayProp] = [];
                }
                if (!Array.isArray(value)) {
                    value = [value];
                }
                for (const valueElement of value) {
                    if (merged[arrayBracketsProp].indexOf(valueElement) < 0) {
                        merged[arrayBracketsProp].push(valueElement);
                    }
                    if (query[arrayProp].indexOf(valueElement) < 0) {
                        query[arrayProp].push(valueElement);
                    }
                }
                if (!property.endsWith('[]')) {
                    delete merged[property];
                }
                continue;
            }
            query[property] = value;
        }
        let url = window.location.href.split('?')[0];
        if (Object.keys(query).length > 0) {
            url += '?' + QueryString.stringify(query, {arrayFormat: 'brackets'})
        }
        url += window.location.hash;
        history.pushState(merged, document.querySelector('title')!.innerText, url);
    }

    private replaceSnippets(snippets: { [key: string]: string }): void {
        this.destroyWidgets();
        for (const snippetName in snippets) {
            if (!Object.prototype.hasOwnProperty.call(snippets, snippetName)) {
                continue;
            }
            const snippetValue = snippets[snippetName];
            const element = this.element.querySelector<HTMLElement>(
                '[data-datagrid-snippet="' + snippetName + '"]',
            );
            if (element === null) {
                continue;
            }
            element.innerHTML = snippetValue;
        }
        componentsLoader.load(this.$element);
    }
}

export type Config = {
    url?: string | null;
    name?: string;
    dataTransportMethod?: 'GET' | 'POST';
    messages?: {
        loading?: string;
        error?: string;
    };
    pageParamName?: string;
    limitParamName?: string;
    orderByPropertyParamName?: string;
    orderByDirectionParamName?: string;
    page?: number;
    limit?: number;
    modifyHistory?: boolean;
}

type Response = {
    snippets: { [key: string]: string }
}

class Pagination
{
    private readonly datagrid: Datagrid;
    private readonly pageLinks: NodeListOf<HTMLLinkElement>;
    private readonly limitSelect: HTMLSelectElement | null;
    private readonly pageSelect: HTMLSelectElement | null;

    constructor(datagrid: Datagrid) {
        this.datagrid = datagrid;
        this.pageLinks = datagrid.element.querySelectorAll('[data-datagrid-page]');
        this.limitSelect = datagrid.element.querySelector<HTMLSelectElement>('[data-datagrid-limit-select]');
        this.pageSelect = datagrid.element.querySelector<HTMLSelectElement>('[data-datagrid-page-select]');
        this.pageLinks.forEach((link) =>
            link.addEventListener('click', (event) => this.onPageLinkClick(event, link)),
        );
        if (this.limitSelect !== null) {
            this.limitSelect.onchange = null;
            this.limitSelect.addEventListener('change', () => this.onLimitSelectChange());
        }
        if (this.pageSelect !== null) {
            this.pageSelect.onchange = null;
            this.pageSelect.addEventListener('change', () => this.onPageSelectChange());
        }
    }

    private onPageLinkClick(event: MouseEvent, link: HTMLLinkElement): void {
        event.preventDefault();
        this.datagrid.navigateToPage(parseInt(link.dataset.datagridPage ?? '1'));
    }

    private onPageSelectChange(): void {
        this.datagrid.navigateToPage(parseInt(this.pageSelect!.value));
    }

    private onLimitSelectChange(): void {
        this.datagrid.changeLimit(parseInt(this.limitSelect!.value));
    }
}

class Sorting
{
    private readonly datagrid: Datagrid;
    private readonly sortControls: NodeListOf<HTMLElement>;

    constructor(datagrid: Datagrid) {
        this.datagrid = datagrid;
        this.sortControls = datagrid.element.querySelectorAll<HTMLElement>('[data-datagrid-sort]');
        this.sortControls.forEach((control) =>
            control.addEventListener('click', (event) =>
                this.onSortControlClick(event, control),
            ),
        );
    }

    private onSortControlClick(event: MouseEvent, control: HTMLElement): void {
        event.preventDefault();
        if (control.hasAttribute('data-datagrid-sort-active')) {
            this.datagrid.changeSortBy(null);
            return;
        }
        this.datagrid.changeSortBy(
            control.dataset.datagridSortByProperty ?? null,
            control.dataset.datagridSortByDirection ?? null,
        );
    }
}

class Filter
{
    private readonly datagrid: Datagrid;
    private readonly form: HTMLFormElement | null;

    constructor(datagrid: Datagrid) {
        this.datagrid = datagrid;
        this.form = datagrid.element.querySelector<HTMLFormElement>('[data-datagrid-snippet=filter] form');
        if (this.form !== null) {
            this.form.addEventListener('submit', (event: SubmitEvent) => this.onFormSubmit(event));
        }
    }

    public getName(): string | null {
        return this.form?.name ?? null;
    }

    public getSerializedData(): { [key: string]: string } {
        if (this.form === null) {
            return {};
        }
        const arrayIndexRegistry: { [key: string]: number } = {};
        const serializedData: { [key: string]: string } = {};
        for (const pair of new FormData(this.form!).entries()) {
            const key = pair[0];
            const value = pair[1];
            if (key.endsWith('[]')) {
                if (typeof arrayIndexRegistry[key] === 'undefined') {
                    arrayIndexRegistry[key] = -1;
                }
                const index = arrayIndexRegistry[key] + 1;
                arrayIndexRegistry[key] = index;
                serializedData[key.slice(0, -2) + '[' + index + ']'] = value.toString();
                continue;
            }
            serializedData[key] = value.toString();
        }
        return serializedData;
    }

    private onFormSubmit(event: SubmitEvent): void {
        event.preventDefault();
        event.stopPropagation();
        this.datagrid.navigateToPage(1);
    }
}

class CollapsedActions
{
    private readonly _datagrid: Datagrid;
    private readonly dropdown: HTMLDivElement;
    private readonly toggler: HTMLButtonElement;
    private _isOpened: boolean = false;

    private static initializedItems: CollapsedActions[] = [];
    private static hasGlobalEventsBound: boolean = false;

    private constructor(datagrid: Datagrid, container: HTMLDivElement) {
        this._datagrid = datagrid;
        this.dropdown = container.querySelector<HTMLDivElement>('[data-datagrid-collapsed-actions-dropdown]')!;
        this.dropdown.style.display = 'none';
        this.toggler = container.querySelector<HTMLButtonElement>('[data-datagrid-collapsed-actions-toggler]')!;
        this.toggler.addEventListener('click', (event) => {
            event.stopPropagation();
            this.closeOtherDropdowns();
            this.toggle();
        });
        this.dropdown.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    }

    private closeOtherDropdowns(): void {
        for (let collapsableActions of CollapsedActions.initializedItems) {
            if (collapsableActions !== this) {
                collapsableActions.close();
            }
        }
    }

    public open(): void {
        if (this.isOpened) {
            return;
        }
        this.dropdown.style.display = 'block';
        this._isOpened = true;
        const rect = this.toggler.getBoundingClientRect();
        const bodyRect = document.body.getBoundingClientRect();
        this.dropdown.style.left = (rect.left - bodyRect.left - this.dropdown.offsetWidth + this.toggler.offsetWidth) + 'px';
        this.dropdown.style.top = (rect.top - bodyRect.top + this.toggler.offsetHeight) + 'px';
    }

    public close(): void {
        if (!this.isOpened) {
            return;
        }
        this.dropdown.style.display = 'none';
        this._isOpened = false;
    }

    public toggle(): void {
        this.isOpened ? this.close() : this.open();
    }

    public get isOpened(): boolean {
        return this._isOpened;
    }

    public get datagrid(): Datagrid {
        return this._datagrid;
    }

    public static initializeAll(datagrid: Datagrid): CollapsedActions[] {
        if (!CollapsedActions.hasGlobalEventsBound) {
            document.addEventListener('click', () => {
                for (let collapsableActions of CollapsedActions.initializedItems) {
                    collapsableActions.close();
                }
            });
            window.addEventListener('resize', () => {
                for (let collapsableActions of CollapsedActions.initializedItems) {
                    collapsableActions.close();
                }
            });
            CollapsedActions.hasGlobalEventsBound = true;
        }
        const output = [];
        const containers = datagrid.element.querySelectorAll<HTMLDivElement>('[data-datagrid-collapsed-actions-container]');
        for (let container of containers) {
            const collapsableActions = new CollapsedActions(datagrid, container);
            output.push(collapsableActions);
            CollapsedActions.initializedItems.push(collapsableActions);
        }
        return output;
    }

    public static destroyInitializedItems(): void {
        CollapsedActions.initializedItems = [];
    }
}
