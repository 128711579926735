import loadingIcon from 'public/files/loading-animation-green.svg';

export default class Loader
{
    private static readonly elementKey = '__LOADER_ELEMENT_KEY__';
    private static readonly positionKey = '__LOADER_DEFAULT_POSITION__';

    public static showOverElement(element: HTMLElement | JQuery) {
        if (element instanceof HTMLElement) {
            element = $(element);
        }
        if (element.data(Loader.elementKey) !== undefined) {
            return;
        }
        const position = element[0].style.position;
        if (!String.isNullOrWhiteSpace(position)) {
            element.data(Loader.positionKey, position);
        }
        element.css('position', 'relative');
        const loadingElement = $('<div class="loading-preview">')
            .append($('<div class="loading-preview__inner-div">')
                .append($(`<img class="loading-preview__icon" alt="Animace načítání" src="${loadingIcon}">`)));
        element.data(Loader.elementKey, loadingElement);
        element.append(loadingElement);
    }

    public static hideOverElement(element: HTMLElement | JQuery) {
        if (element instanceof HTMLElement) {
            element = $(element);
        }
        if (element.data(Loader.elementKey) === undefined) {
            return;
        }
        const loadingElement = element.data(Loader.elementKey) as JQuery;
        loadingElement.remove();
        element.removeData(Loader.elementKey);
        const position = element.data(Loader.positionKey);
        if (position !== undefined) {
            element.removeData(Loader.positionKey);
        }
        element.css('position', position === undefined ? '' : position);
    }
}
