type PropertyChangedInfo = {
    element: HTMLElement,
    property: string,
}

class ElementAttrChangeObserver
{
    public observe(
        element: HTMLElement,
        attr: string[] | string,
        onChange: (propertyChangedInfo: PropertyChangedInfo) => void): void {
        if (typeof attr === 'string') {
            attr = [attr];
        }
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type !== 'attributes') {
                    return;
                }
                observer.disconnect();
                onChange({
                    element: element,
                    property: mutation.attributeName as string,
                });
                observer.observe(element, {attributes: true, attributeFilter: attr as string[]});
            });
        });
        observer.observe(element, {attributes: true, attributeFilter: attr as string[]});
    }
}

const elementAttrChangeObserver = new ElementAttrChangeObserver();

export default elementAttrChangeObserver;
