import {AxiosError} from 'axios';
import {messenger} from 'app/utils/messenger';
import {logger} from 'app/service/logger';

export function handleAxiosError(error: AxiosError): void {
    // @ts-ignore
    const message = error.response?.data?.message ?? undefined;
    if (typeof message === 'string') {
        messenger.error(message);
    } else {
        messenger.error(APP_CONFIG.messages.common.error);
    }
    handleAxiosErrorSilently(error);
}

export function handleAxiosErrorSilently(error: AxiosError): void {
    logger.log(error.status);
    logger.log(error.code);
    logger.log(error.response?.data);
}
