import Component from 'app/components/component';
import InvalidArgumentException from 'app/errors/invalid-argument-exception';
import ClickEvent = JQuery.ClickEvent;

export default class Tab extends Component<Config, HTMLDivElement>
{
    private _tabItems: JQuery = $([]);
    private tabContents: JQuery = $([]);
    private _selectedTabItem: JQuery | null = null;
    private _previousSelectedTabItem: JQuery | null = null;
    private _areTabItemsEnabled: boolean = true;

    public static readonly defaultConfig: Partial<Config> = {
        changeUrl: false,
    };

    constructor(element: HTMLDivElement, config: Config) {
        super(element, config, false);
        this.initialize();
    }

    protected initialize() {
        this.$element.findWithSelf('[data-tab-item]').each((_, tabItem) => {
            this.addTabItem($(tabItem));
        });
        const selectedTabItem = this.getSelectedTabItem();
        if (selectedTabItem !== null) {
            this.setSelectedTabItem(selectedTabItem);
        }
    }

    public getComponentName(): string {
        return 'Tab';
    }

    public get selectedTabItem(): JQuery | null {
        return this._selectedTabItem;
    }

    public get previousSelectedTabItem(): JQuery | null {
        return this._previousSelectedTabItem;
    }

    private set selectedTabItem(value: JQuery | null) {
        if (
            (
                this._selectedTabItem === null && value === null
            ) || (
                this._selectedTabItem !== null && value !== null && this._selectedTabItem[0] === value[0]
            )
        ) {
            return;
        }
        this._previousSelectedTabItem = this._selectedTabItem;
        this._selectedTabItem = value;
        if (this._previousSelectedTabItem !== null) {
            this.triggerEvent(TabEvents.TabUnselected, null, {
                tabItem: this._previousSelectedTabItem,
                tabContent: this.getTabContent(this._previousSelectedTabItem),
            });
        }
        if (this._selectedTabItem !== null) {
            this.triggerEvent(TabEvents.TabSelected, null, {
                tabItem: this._selectedTabItem,
                tabContent: this.getTabContent(this._selectedTabItem),
            });
        }
    }

    public get selectedTabContent(): JQuery | null {
        return this._selectedTabItem === null ? null : this.getTabContent(this._selectedTabItem);
    }

    public get previousSelectedTabContent(): JQuery | null {
        return this._previousSelectedTabItem === null ? null : this.getTabContent(this._previousSelectedTabItem);
    }

    public disableTabItems(): void {
        if (!this.areTabItemsEnabled) {
            return;
        }
        this._tabItems.addClass('tab-header__item--is-disabled');
        this.areTabItemsEnabled = true;
    }

    public enableTabItems(): void {
        if (this.areTabItemsEnabled) {
            return;
        }
        this._tabItems.removeClass('tab-header__item--is-disabled');
        this.areTabItemsEnabled = false;
    }

    public get areTabItemsEnabled(): boolean {
        return this._areTabItemsEnabled;
    }

    private set areTabItemsEnabled(value: boolean) {
        this._areTabItemsEnabled = value;
    }

    private onTabItemClick(event: ClickEvent): void {
        if (!this.areTabItemsEnabled) {
            return;
        }
        let tabItem = $(event.target as HTMLLinkElement);
        if (tabItem.prop('tagName') !== 'A') {
            tabItem = tabItem.closest('a');
        }
        this.setSelectedTabItem(tabItem);
        const target = tabItem.attr('href');
        if (typeof target === 'string' && target.startsWith('#') && !this.config.changeUrl) {
            event.preventDefault();
        }
    }

    private getSelectedTabItem(): JQuery | null {
        if (this._tabItems.length < 1) {
            return null;
        }
        let selected = $(this._tabItems[0]);
        this._tabItems.each((_, element) => {
            const $element = $(element);
            if ($element.hasClass('tab-header__item--is-selected')) {
                selected = $element;
            }
        });
        return selected;
    }

    public getTabItemsLength(): number {
        return this._tabItems.length;
    }

    public getFirstTabItem(): JQuery | null {
        return this._tabItems.length > 0 ? $(this._tabItems[0]) : null;
    }

    public getLastTabItem(): JQuery | null {
        return this._tabItems.length > 0 ? $(this._tabItems[this._tabItems.length - 1]) : null;
    }

    public getTabContent(tabItem: JQuery): JQuery | null {
        const target = tabItem.attr('href');
        if (typeof target !== 'string' || !target.startsWith('#')) {
            return null;
        }
        const content = $(target);
        return content.length > 0 ? content : null;
    }

    public getTabItem(tabContent: JQuery): JQuery | null {
        const id = tabContent.attr('id');
        if (typeof id !== 'string') {
            return null;
        }
        for (let i = 0; i < this.tabItems.length; i++) {
            const tabItem = $(this.tabItems[i]);
            const target = tabItem.attr('href');
            if (typeof target !== 'string' || !target.startsWith('#')) {
                continue;
            }
            if (id === target.substring(1)) {
                return tabItem;
            }
        }
        return null;
    }

    public getTabItemByIndex(index: number): JQuery | null {
        if (index < 0 || index >= this._tabItems.length) {
            return null;
        }
        return $(this._tabItems[index]);
    }

    public setSelectedTabItem(tabItem: JQuery) {
        if (tabItem.length !== 1) {
            throw new InvalidArgumentException('JQuery object has to contain exactly one element.');
        }
        if (!$.contains(this.element, tabItem[0])) {
            throw new InvalidArgumentException('Provided tab item is not inside this component.');
        }
        this._tabItems.removeClass('tab-header__item--is-selected');
        tabItem.addClass('tab-header__item--is-selected');
        const tabContent = this.getTabContent(tabItem);
        if (tabContent !== null) {
            this.tabContents.css('display', 'none');
            tabContent.css('display', 'block');
        }
        this.selectedTabItem = tabItem;
    }

    public setSelectedTabItemByIndex(index: number) {
        const tabItem = this.getTabItemByIndex(index);
        if (tabItem === null) {
            throw new InvalidArgumentException('Provided index is out of range.');
        }
        this.setSelectedTabItem(tabItem);
    }

    public setFirstTabItemAsSelected() {
        const tabItem = this.getFirstTabItem();
        if (tabItem === null) {
            throw new InvalidArgumentException('There are no tab items.');
        }
        this.setSelectedTabItem(tabItem);
    }

    public setLastTabItemAsSelected() {
        const tabItem = this.getLastTabItem();
        if (tabItem === null) {
            throw new InvalidArgumentException('There are no tab items.');
        }
        this.setSelectedTabItem(tabItem);
    }

    public addTabItem(tabItem: JQuery, position: number | null = null): void {
        if (tabItem.length !== 1) {
            throw new InvalidArgumentException('JQuery object has to contain exactly one element.');
        }
        if (!$.contains(this.element, tabItem[0])) {
            const $tabItems = this.$element.find('.tab-header__items');
            if (position !== null && position >= this.tabItems.length) {
                position = null;
            }
            if (position === null) {
                $tabItems.findWithSelf('.tab-header__items').append(tabItem);
            } else if (position <= 0) {
                $tabItems.findWithSelf('.tab-header__items').prepend(tabItem);
            } else {
                tabItem.insertBefore($(this.tabItems[position]));
            }
        }
        this._tabItems = this._tabItems.add(tabItem);
        const tabContent = this.getTabContent(tabItem);
        if (tabContent !== null) {
            this.tabContents = this.tabContents.add(tabContent);
        }
        tabItem.on('click', (event: ClickEvent) => this.onTabItemClick(event));
    }

    public removeTabItem(tabItem: JQuery, removeTabContent: boolean = false): void {
        if (tabItem.length !== 1) {
            throw new InvalidArgumentException('JQuery object has to contain exactly one element.');
        }
        const replaceSelected = this._selectedTabItem !== null && this._selectedTabItem[0] === tabItem[0];
        if ($.contains(this.element, tabItem[0])) {
            tabItem.remove();
        }
        this._tabItems = this._tabItems.filter((_, tabItemEl): boolean => {
            if (tabItem[0] !== tabItemEl) {
                return true;
            }
            const tabContent = this.getTabContent(tabItem);
            if (tabContent !== null) {
                this.tabContents = this.tabContents.filter((_, tabContentEl): boolean => tabContent[0] !== tabContentEl);
                if (removeTabContent) {
                    tabContent.remove();
                }
            }
            return false;
        });
        tabItem.off('click');
        if (replaceSelected) {
            const selectedTabItem = this.getSelectedTabItem();
            if (selectedTabItem !== null) {
                this.setSelectedTabItem(selectedTabItem);
            }
        }
    }

    public get tabItems(): JQuery {
        return this._tabItems;
    }
}

export enum TabEvents
{
    TabSelected = 'app.tab.tab-selected',
    TabUnselected = 'app.tab.tab-unselected',
}

type Config = {
    changeUrl: boolean;
}
