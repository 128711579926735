import {ActionEvent, Controller} from '@hotwired/stimulus';

export default class RsFwdFormInvestmentQuestionnaireV2Controller extends Controller
{
    declare private entry5TableTarget: HTMLTableElement;
    declare private entry6TableTarget: HTMLTableElement;
    declare private entry7ExtraInfo0Target: HTMLInputElement;
    declare private entry7ExtraInfo1Target: HTMLInputElement;
    declare private entry7ExtraInfo2Target: HTMLInputElement;
    declare private containerEntry7ExtraInfo0Target: HTMLDivElement;
    declare private containerEntry7ExtraInfo1Target: HTMLDivElement;
    declare private containerEntry7ExtraInfo2Target: HTMLDivElement;

    static targets = [
        'entry5Table',
        'entry6Table',
        'entry7ExtraInfo0',
        'entry7ExtraInfo1',
        'entry7ExtraInfo2',
        'containerEntry7ExtraInfo0',
        'containerEntry7ExtraInfo1',
        'containerEntry7ExtraInfo2',
    ];

    public answerSelected(event: ActionEvent): void {
        const questionNumber = event.params.questionNumber;
        const tag = event.params.tag;
        if (questionNumber === 4) {
            this.entry5TableTarget.style.display = tag === 'a' ? 'none' : 'table';
            this.entry6TableTarget.style.display = tag === 'a' ? 'none' : 'table';
        } else if (questionNumber === 7) {
            this.entry7ExtraInfo0Target.disabled = tag !== 'b';
            this.containerEntry7ExtraInfo0Target.style.display = tag === 'b' ? 'block' : 'none';
            this.entry7ExtraInfo1Target.disabled = tag !== 'c';
            this.containerEntry7ExtraInfo1Target.style.display = tag === 'c' ? 'block' : 'none';
            this.entry7ExtraInfo2Target.disabled = tag !== 'd';
            this.containerEntry7ExtraInfo2Target.style.display = tag === 'd' ? 'block' : 'none';
        }
    }
}
