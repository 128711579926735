import Component from 'app/components/component';
import 'jquery-datetimepicker';
import 'jquery-datetimepicker/build/jquery.datetimepicker.min.css';

$.datetimepicker.setLocale(APP_CONFIG.common.locale);

export default class DateTimePicker extends Component<Config, HTMLInputElement>
{
    public static readonly defaultConfig: Partial<Config> = {
        format: 'd.m.Y H:i',
        enableDatePicker: true,
        enableTimePicker: true,
        minDate: null,
        maxDate: null,
    };

    protected initialize() {
        this.$element.attr('autocomplete', 'off');
        const config: { [_key: string]: any } = {
            format: this.config.format,
            scrollInput: false,
            timepicker: this.config.enableTimePicker,
            datepicker: this.config.enableDatePicker,
        };
        if (this.config.minDate !== null) {
            config.minDate = this.config.minDate;
        }
        if (this.config.maxDate !== null) {
            config.maxDate = this.config.maxDate;
        }
        this.$element.datetimepicker(config);
    }

    public getComponentName(): string {
        return 'DateTimePicker';
    }

    public show(): void {
        this.$element.datetimepicker('show');
    }

    public hide(): void {
        this.$element.datetimepicker('hide');
    }

    public reset(): void {
        this.$element.datetimepicker('reset');
    }

    public getValue(): Date {
        return this.$element.datetimepicker('getValue') as unknown as Date;
    }
}

type Config = {
    format: string;
    enableDatePicker: boolean;
    enableTimePicker: boolean;
    minDate: string | null;
    maxDate: string | null;
};
