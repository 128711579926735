import '@fortawesome/fontawesome-free/css/all.css';
import 'app/extension/_extensions';
import 'app/styles/app.scss';
import {componentsLoader} from 'app/service/components-loader';
import {logger} from 'app/service/logger';
import {flashModalMessenger} from 'app/service/flash-modal-messenger';
import { startStimulusApp } from '@symfony/stimulus-bridge';

// Registers Stimulus controllers from controllers.json and in the controllers/ directory
export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.[jt]sx?$/
));
// register any custom, 3rd party controllers here
// app.register('some_controller_name', SomeImportedController);

componentsLoader.load();
flashModalMessenger.handle();

const url = new URL(window.location.href);
if (url.searchParams.has('close-window')) {
    try {
        window.close();
    } catch (e) {
        logger.log(e);
    }
}

