import Component from 'app/components/component';

export default class DocumentationList extends Component {
    public getComponentName(): string {
        return 'DocumentationList';
    }

    protected initialize() {
        const toggleBtn = this.$element.findWithSelf('[data-show-files-btn]');
        toggleBtn.on('click', () => {
            toggleBtn.toggleClass('upside-down');
            this.$element.findWithSelf('[data-documentation-files]').toggleClass('d-none');
        })
    }
}
