import Component from "app/components/component";

export default class ScrollToId extends Component {
    public getComponentName(): string {
        return 'ScrollToId';
    }

    protected initialize() {
        this.$element.on('click', () => {
            const scrollId = this.$element.data('scrollId');
            document.getElementById(scrollId)?.scrollIntoView();
        })
    }
}
