import Component, {EmptyConfig} from 'app/components/component';

export default class PasswordInput extends Component<EmptyConfig, HTMLInputElement>
{
    private toggleBtn: JQuery;
    private icon: JQuery;
    private isVisible: boolean = false;

    protected initialize() {
        const widget = $(`
            <div class="input-group">
                <div class="input-group__input"></div>
                <div class="input-group__addons">
                    <button type="button" class="input-group__addon-btn input-group__addon-btn--secondary">
                        <i class="fa fa-eye"></i>
                    </button>
                </div>
            </div>
        `);
        widget.insertAfter(this.$element);
        widget.find('.input-group__input').append(this.$element);
        this.toggleBtn = widget.find('.input-group__addon-btn');
        this.icon = this.toggleBtn.find('i');
        this.refreshBindings();
    }

    public refreshBindings(): void {
        const clickEvent = this.getScopedClickEvent();
        this.toggleBtn.off(clickEvent);
        this.toggleBtn.on(clickEvent, () => this.onToggleBtnClick());
    }

    protected onToggleBtnClick(): void {
        this.isVisible = !this.isVisible;
        if (this.isVisible) {
            this.$element.attr('type', 'text');
            this.icon.removeClass('fa-eye');
            this.icon.addClass('fa-eye-slash');
            return;
        }
        this.$element.attr('type', 'password');
        this.icon.addClass('fa-eye');
        this.icon.removeClass('fa-eye-slash');
    }

    public getComponentName(): string {
        return 'PasswordInput';
    }
}
