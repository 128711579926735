import Component from 'app/components/component';
import UnexpectedValueException from 'app/errors/unexpected-value-exception';
import {componentsLoader} from 'app/service/components-loader';

export default class PersistedEventFilterForm extends Component
{
    private auditEntityFiltersAddBtn: JQuery;
    private auditEntityFiltersItems: JQuery;
    private auditEntityFiltersRemoveBtn: JQuery;

    public getComponentName(): string {
        return 'PersistedEventFilterForm';
    }

    protected initialize() {
        this.refreshBindings();
    }

    public refreshBindings() {
        const clickEvent = this.getScopedClickEvent();
        this.auditEntityFiltersAddBtn = this.$element.find('[data-persisted-event-filter-form-audit-entity-filters-add-btn]');
        this.auditEntityFiltersItems = this.$element.find('[data-persisted-event-filter-form-audit-entity-filters-items]');
        this.auditEntityFiltersRemoveBtn = this.$element.find('[data-persisted-event-filter-form-audit-entity-filters-item-remove-btn]');
        this.auditEntityFiltersAddBtn.off(clickEvent);
        this.auditEntityFiltersAddBtn.on(clickEvent, () => this.handleAuditEntityFilterAddBtnClick());
        this.auditEntityFiltersRemoveBtn.off(clickEvent);
        this.auditEntityFiltersRemoveBtn.on(clickEvent, (event) => this.handleAuditEntityFilterRemoveBtnClick(event as JQuery.ClickEvent));
    }

    private handleAuditEntityFilterAddBtnClick(): void {
        const prototype = this.auditEntityFiltersAddBtn.data('persistedEventFilterFormAuditEntityFiltersPrototype') as unknown;
        const itemIndexKey = 'persistedEventFilterFormAuditEntityFiltersMaxItemIndex';
        const lastItemIndex = this.auditEntityFiltersAddBtn.data(itemIndexKey) as unknown;
        if (typeof lastItemIndex !== 'number') {
            throw new UnexpectedValueException('Number was expected.');
        }
        if (typeof prototype !== 'string') {
            throw new UnexpectedValueException('String was expected.');
        }
        this.auditEntityFiltersAddBtn.data(itemIndexKey, lastItemIndex + 1);
        const itemIndex = (lastItemIndex + 1).toString();
        const itemHtml = String.decodeHtmlEntities(prototype).replace(/__name__/g, itemIndex);
        const item = $(itemHtml);
        componentsLoader.load(item);
        this.auditEntityFiltersItems.append(item);
        this.refreshBindings();
    }

    private handleAuditEntityFilterRemoveBtnClick(event: JQuery.ClickEvent): void {
        const removeBtn = event.currentTarget as JQuery;
        const itemContainer = removeBtn.closest('[data-persisted-event-filter-form-audit-entity-filters-item]');
        itemContainer.remove();
    }
}
