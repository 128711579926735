import {Controller} from '@hotwired/stimulus';
import {ClickEventDetail} from 'app/controllers/notifications-reader-list-item-controller';
import {logger} from 'app/service/logger';
import {messenger} from 'app/utils/messenger';

export default class NotificationsReaderController extends Controller
{
    private titleTarget!: HTMLElement;
    private descriptionTarget!: HTMLElement;
    private dateTarget!: HTMLElement;
    private listTarget!: HTMLElement;
    private loadingClass!: string;
    private pageParamNameValue!: string;
    private listUrlValue!: string;
    private hasNextPageValue!: boolean;
    private isLoading: boolean = false;
    private scrollTimeout: NodeJS.Timeout | null = null;
    private page: number = 1;

    static targets = ['title', 'description', 'date', 'list'];
    static classes = ['loading'];
    static values = {pageParamName: String, listUrl: String, hasNextPage: Boolean};

    public connect() {
        window.addEventListener('notifications-reader-list-item:click', (event) => this.handleListItemClick(event as CustomEvent<ClickEventDetail>));
        this.listTarget.addEventListener('scroll', () => this.handleListScroll());
        this.handleListScroll();
    }

    private handleListItemClick(event: CustomEvent<ClickEventDetail>): void {
        const $description = $(this.descriptionTarget);
        $description.fadeOut(150, () => {
            this.titleTarget.innerHTML = event.detail.title;
            this.descriptionTarget.innerHTML = event.detail.description;
            this.dateTarget.innerHTML = event.detail.date;
            this.titleTarget.id = 'notification_' + event.detail.id;
            $description.fadeIn(150);
            this.descriptionTarget.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        });
    }

    private handleListScroll(): void {
        if (this.scrollTimeout !== null) {
            clearTimeout(this.scrollTimeout);
        }
        this.scrollTimeout = setTimeout(async () => {
            if (this.isLoading || !this.hasNextPageValue) {
                return;
            }
            if (!(this.listTarget.scrollHeight - this.listTarget.offsetHeight - 10 <= this.listTarget.scrollTop)) {
                return;
            }
            try {
                this.isLoading = true;
                this.element.classList.add(this.loadingClass);
                const url = new URL(this.listUrlValue);
                url.searchParams.set(this.pageParamNameValue, (this.page + 1).toString());
                const response = await fetch(url.toString(), {method: 'GET'});
                const json: Response = await response.json();
                if (!String.isNullOrWhiteSpace(json.html)) {
                    this.listTarget.innerHTML += json.html;
                }
                this.page = json.pagination.page;
                this.hasNextPageValue = json.pagination.hasNext;

                this.handleListScroll();
            } catch (error) {
                logger.log(error);
                messenger.error(APP_CONFIG.messages.common.error);
            } finally {
                this.isLoading = false;
                this.element.classList.remove(this.loadingClass);
            }
        }, 500);
    }
}

type Response = {
    html: string;
    pagination: {
        page: number;
        total: number;
        pages: number;
        hasNext: boolean;
    }
}
