import Component from 'app/components/component';
import IdentityCheck, {IdentityCheckEvents} from 'app/components/identity-check';
import requiredAttrInputToggler from 'app/utils/required-attr-input-toggler';

export default class RsFwdSignatureProcessForm extends Component
{
    private viewDocumentBtn: JQuery;
    private documentViewer: JQuery;
    private formActions: JQuery;
    private areSignaturesAcceptedCheckbox: JQuery;
    private signaturesDeclineContainer: JQuery;
    private signaturesAcceptContainer: JQuery;
    private identityCheck: IdentityCheck | null;

    protected initialize() {
        this.refreshBindings();
        this.showDefaultDocumentViewer();
        this.onAreSignaturesAcceptedChange();
    }

    public refreshBindings() {
        this.viewDocumentBtn = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-document-view-btn]');
        this.documentViewer = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-document-viewer]');
        this.formActions = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-actions]');
        this.areSignaturesAcceptedCheckbox = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-are-signatures-accepted]');
        this.signaturesDeclineContainer = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-signatures-decline-container]');
        this.signaturesAcceptContainer = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-signatures-accept-container]');
        const clickEvent = this.getScopedClickEvent();
        this.viewDocumentBtn.off(clickEvent);
        this.viewDocumentBtn.on(clickEvent, (event) => this.onViewDocumentBtnClick(event as JQuery.ClickEvent));
        if (this.getCurrentStepLabel() === FormStep.IdentityCheck) {
            this.identityCheck = IdentityCheck.getOne(this.$element.findWithSelf('[data-identity-check]')) as IdentityCheck;
            if (!this.identityCheck.isVerified) {
                this.hideFormActions();
                this.identityCheck.addEventListener(IdentityCheckEvents.VERIFIED, () => {
                    this.showFormActions();
                    this.switchMessageInIndentyCheckPromptContainer();
                });
            }
        }
        requiredAttrInputToggler.register(this.signaturesAcceptContainer);
        requiredAttrInputToggler.register(this.signaturesDeclineContainer);
        const changeEvent = this.getScopedChangeEvent();
        this.areSignaturesAcceptedCheckbox.off(changeEvent);
        this.areSignaturesAcceptedCheckbox.on(changeEvent, () => this.onAreSignaturesAcceptedChange());
    }

    public getComponentName(): string {
        return 'RsFwdSignatureProcessForm';
    }

    private showDefaultDocumentViewer(): void {
        this.clearDocumentViewer();
        if (this.viewDocumentBtn.length > 0) {
            $(this.viewDocumentBtn[0]).trigger(this.getScopedClickEvent());
        }
    }

    public getCurrentStepNumber(): number {
        return parseInt(this.$element.attr('data-rs-fwd-signature-process-form-step') as string);
    }

    public getCurrentStepLabel(): string {
        return this.$element.attr('data-rs-fwd-signature-process-form-step-label') as string;
    }

    private onViewDocumentBtnClick(event: JQuery.ClickEvent): void {
        this.clearDocumentViewer();
        let btn = $(event.target);
        if (btn.prop('tagName') !== 'BUTTON') {
            btn = btn.closest('button');
        }
        const url = btn.data('rsFwdSignatureProcessFormDocumentUrl');
        this.showDocumentViewer(url);
    }

    private clearDocumentViewer(): void {
        this.documentViewer.empty();
        this.documentViewer.hide();
    }

    private showDocumentViewer(documentUrl: string): void {
        this.documentViewer.show();
        let html = this.documentViewer.data('rsFwdSignatureProcessFormDocumentViewerPrototype') as string;
        html = html.replace(/__document_url__/g, encodeURIComponent(documentUrl));
        this.documentViewer.html(html);
    }

    private hideFormActions(): void {
        this.formActions.hide();
    }

    private showFormActions(): void {
        this.formActions.show();
    }

    private onAreSignaturesAcceptedChange(): void {
        if (this.areSignaturesAcceptedCheckbox.length === 0) {
            return;
        }
        if (this.areSignaturesAcceptedCheckbox.is(':checked')) {
            requiredAttrInputToggler.setAsRequired(this.signaturesAcceptContainer);
            requiredAttrInputToggler.setAsNotRequired(this.signaturesDeclineContainer);
            this.signaturesAcceptContainer.show();
            this.signaturesDeclineContainer.hide();
            return;
        }
        requiredAttrInputToggler.setAsNotRequired(this.signaturesAcceptContainer);
        requiredAttrInputToggler.setAsRequired(this.signaturesDeclineContainer);
        this.signaturesAcceptContainer.hide();
        this.signaturesDeclineContainer.show();
        return;
    }

    private switchMessageInIndentyCheckPromptContainer(): void {
        const container = this.$element.findWithSelf('[data-rs-fwd-signature-process-form-identity-check-prompt-container]');
        const message = container.data('rsFwdSignatureProcessFormIdentityCheckCompletedMessage');
        container.html(message);
    }
}

enum FormStep {
    IdentityCheck = 'identity_check',
    Documents = 'documents',
    Signatures = 'signatures',
}
