import Modal, {ModalEvents} from 'app/components/modal';

class FlashModalMessenger
{
    private registry: Modal[] = [];
    private stack: Modal[] = [];
    private _current: Modal | null = null;

    public get current(): Modal | null {
        return this._current;
    }

    public handle(): void {
        this.buildRegistry();
        this.fillStackFromRegistry();
        this.popFromStack();
    }

    private popFromStack(): void {
        if (this._current !== null) {
            if (this._current.isShown) {
                this._current.hide();
                return;
            }
            this._current.$element.remove();
        }
        if (this.stack.length === 0) {
            return;
        }
        this._current = this.stack.pop() as Modal;
        this._current.show();
    }

    private fillStackFromRegistry(): void {
        for (const modal of this.registry) {
            this.stack.push(modal);
        }
    }

    private buildRegistry(): void {
        this.registry = [];
        const elements = document.querySelectorAll<HTMLDivElement>('[data-flash-modal]');
        for (const element of elements) {
            const modal = Modal.getOne(element) as Modal;
            if (modal !== null) {
                modal.addEventListener(ModalEvents.Hidden, () => this.popFromStack());
                this.registry.push(modal);
            }
        }
    }
}

export const flashModalMessenger = new FlashModalMessenger();
