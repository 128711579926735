import Component from 'app/components/component';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {handleAxiosError} from 'app/utils/axios';
import {logger} from 'app/service/logger';
import {messenger} from 'app/utils/messenger';

export default class VerificationCode extends Component<Config>
{
    private actionsEl: JQuery;
    private resendCodeBtn: JQuery;

    public static readonly defaultConfig: Partial<Config> = {
        allowResend: false,
        resendTemplate: null,
        resendUrl: null,
        resendSuccessMessage: null,
        autoCodeSend: false,
    };

    protected initialize() {
        this.refreshBindings();
        if (this.config.autoCodeSend) {
            setTimeout(() => this.resendCode(), 100);
        }
    }

    public refreshBindings() {
        this.actionsEl = this.$element.findWithSelf('[data-verfication-code-actions]');
        this.resendCodeBtn = this.$element.findWithSelf('[data-verification-code-resend-code]');
        const clickEvent = this.getScopedClickEvent();
        this.resendCodeBtn.off(clickEvent);
        this.resendCodeBtn.on(clickEvent, () => this.resendCode());
    }

    public getComponentName(): string {
        return 'VerificationCode';
    }

    public async resendCode(): Promise<void> {
        if (!this.config.allowResend || this.config.resendUrl === null) {
            return;
        }
        try {
            const response = await axios.request<null, AxiosResponse<Group>>({
                url: this.config.resendUrl,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            logger.log(response.data);
            const lastCode = response.data.lastCode;
            if (lastCode !== null && lastCode.code !== CODE_SECRET) {
                logger.log('Verification code: ' + lastCode.code);
            }
            if (this.config.resendTemplate !== null) {
                this.actionsEl.html(this.config.resendTemplate);
                this.refreshBindings();
            }
            if (this.config.resendSuccessMessage !== null) {
                messenger.success(this.config.resendSuccessMessage);
            }
        } catch (error) {
            handleAxiosError(error as AxiosError);
        }
    }
}

const CODE_SECRET = '__SECRET__';

type Config = {
    allowResend: boolean;
    resendUrl: string | null;
    resendTemplate: string | null;
    resendSuccessMessage: string | null;
    autoCodeSend: boolean;
}

type Code = {
    id: number;
    type: {
        id: number;
        key: string;
    },
    expiresAt: string | null;
    sentAt: string | null;
    usedAt: string | null;
    code: string;
    target: string;
}

type Group = {
    id: number;
    codes: Code[],
    lastCode: Code | null;
    isVerified: boolean;
}
