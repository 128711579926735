import Component from 'app/components/component';
import Random from 'app/utils/random';
import InvalidStateException from 'app/errors/invalid-state-exception';
import axios, {AxiosResponse} from 'axios';
import {componentsLoader} from 'app/service/components-loader';
import {handleAxiosError} from 'app/utils/axios';
import ClickEvent = JQuery.ClickEvent;

$(document).on('click', (event: ClickEvent) => {
    const target = event.target;
    if (ModalOpener.isModalOpener(target)) {
        new ModalOpener($(target as HTMLElement));
    }
});

export default class Modal extends Component<Config, HTMLDivElement>
{
    private readonly _id: number;
    private _isShown: boolean = false;

    private static backdrop: JQuery | null = null;
    private static stack: Modal[] = [];
    private static current: Modal | null = null;

    constructor(element: HTMLDivElement, config: Config) {
        super(element, config, false);
        this._id = Random.integer(0, 1000000);
        this.initialize();
    }

    protected initialize() {
        this.fixDom();
        this.$element.find('[data-modal-close-button]').on('click', () => this.hide());
    }

    public getComponentName(): string {
        return 'Modal';
    }

    public show(): void {
        if (this.isShown) {
            return;
        }
        this.fixDom();
        if (Modal.stack.length === 0) {
            this.showBackdrop();
        }
        Modal.stack.remove(this);
        Modal.stack.push(this);
        if (Modal.current !== null) {
            Modal.current.hideInternal();
        }
        Modal.current = this;
        this.$element.addClass('modal--is-shown');
        this._isShown = true;
        this.triggerEvent(ModalEvents.Shown);
    }

    public hide(): void {
        if (!this.isShown) {
            return;
        }
        if (Modal.current?.id === this.id) {
            Modal.stack.pop();
            this.$element.removeClass('modal--is-shown');
            this._isShown = false;
            if (Modal.stack.length === 0) {
                this.hideBackdrop();
                Modal.current = null;
                this.triggerEvent(ModalEvents.Hidden)
                return;
            }
            Modal.current = Modal.stack[Modal.length - 1];
            Modal.current.showInternal();
            this.triggerEvent(ModalEvents.Hidden)
            return;
        }
        Modal.stack.remove(this);
        this.$element.removeClass('modal--is-shown');
        this._isShown = false;
    }

    public hideAndRemove(): void {
        this.hide();
        this.$element.remove();
    }

    private fixDom(): void {
        const duplicates = document.querySelectorAll('#' + this.$element.attr('id'));
        if (duplicates.length > 1) {
            const lastDuplicateEl = duplicates[duplicates.length - 1];
            if (this.element !== lastDuplicateEl) {
                this.$element.remove();
                return;
            }
        }
        const parent = this.$element.parent();
        if (parent.prop('tagName') !== 'body') {
            $('body').append(this.$element);
        }
    }

    private hideInternal(): void {
        this.$element.css('display', 'none');
    }

    private showInternal(): void {
        this.$element.css('display', 'block');
    }

    public get isShown(): boolean {
        return this._isShown;
    }

    public get id(): number {
        return this._id;
    }

    private showBackdrop(): void {
        $('body').css('overflow', 'hidden');
        this.getBackdrop().css('display', 'block');
    }

    private hideBackdrop(): void {
        $('body').css('overflow', '');
        this.getBackdrop().css('display', 'none');
    }

    private getBackdrop(): JQuery {
        if (Modal.backdrop === null) {
            Modal.backdrop = $('<div class="modal-backdrop" style="display: none;">');
            Modal.backdrop.on('click', () => Modal.onBackdropClick());
            $('body').append(Modal.backdrop);
        }
        return Modal.backdrop;
    }

    private static onBackdropClick(): void {
        Modal.current?.hide();
    }
}

export enum ModalEvents {
    Shown = 'app.modal.shown',
    Hidden = 'app.modal.hidden',
}

type Config = {};

class ModalOpener
{
    private element: JQuery;

    private static readonly dataStoragePrefix: string = 'modal';

    public constructor(element: JQuery) {
        this.element = element;
        if (this.isLoading()) {
            return;
        }
        const modal = this.getModal();
        if (modal !== null) {
            modal.show();
            return;
        }
        const url = this.getUrl();
        const target = this.getTarget();
        if (target !== null) {
            const foundTarget = $(target);
            if (foundTarget.length > 0) {
                const modal = Modal.getOne<Modal>(foundTarget);
                if (modal === null) {
                    throw new InvalidStateException(`Target ${target} is not modal component`);
                }
                this.setModal(modal);
                modal.show();
                return;
            }
            if (url === null) {
                throw new InvalidStateException(`Could not find modal by target ${target}`);
            }
        }
        this.toggleLoading();
        axios
            .request <null, AxiosResponse<ModalOpenerResponse>>({
                url: url as string,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                this.toggleLoading();
                const $modal = $(response.data.html);
                const modal = Modal.getOne<Modal>($modal);
                if (modal === null) {
                    throw new InvalidStateException('Fetched html does not contain modal component.');
                }
                componentsLoader.load($modal);
                $('body').append($modal);
                modal.show();
                this.setModal(modal);
            })
            .catch((error) => {
                this.toggleLoading();
                handleAxiosError(error);
            });
    }

    private getModal(): Modal | null {
        return this.readData<Modal>('modalInstance');
    }

    private setModal(modal: Modal): void {
        this.writeData('modalInstance', modal);
    }

    private getTarget(): string | null {
        return this.readData<string>('target');
    }

    private getUrl(): string | null {
        return this.readData<string>('targetUrl');
    }

    private isLoading(): boolean {
        return this.readData<boolean>('isLoading') === true;
    }

    private toggleLoading(): void {
        const isLoading = this.isLoading();
        this.writeData('isLoading', !isLoading);
    }

    private readData<T>(key: string): T | null {
        const value = this.element.data(ModalOpener.dataStoragePrefix + key.firstToUpper());
        return value === undefined ? null : value;
    }

    private writeData(key: string, value: any): void {
        this.element.data(ModalOpener.dataStoragePrefix + key.firstToUpper(), value);
    }

    static isModalOpener(subject: any): boolean {
        if (!(subject instanceof HTMLElement)) {
            return false;
        }
        const $subject = $(subject);
        return $subject.attr('data-modal-target') !== undefined || $subject.attr('data-modal-target-url') !== undefined;
    }
}

type ModalOpenerResponse = {
    html: string;
}
