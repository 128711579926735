import Component from 'app/components/component';
import InvalidStateException from 'app/errors/invalid-state-exception';
import axios from 'axios';
import {logger} from 'app/service/logger';
import {handleAxiosError} from 'app/utils/axios';
import {componentsLoader} from 'app/service/components-loader';
import elementAttrChangeObserver from 'app/service/element-attr-change-observer';
import Random from 'app/utils/random';

export default class NestedChoiceSelect extends Component<Config, HTMLDivElement>
{
    private input: JQuery;
    private inputValue: JQuery;
    private inputCaret: JQuery;
    private choices: JQuery;
    private choicesInputs: JQuery;
    private headers: JQuery;
    private autocompleteInput: JQuery;
    private autocompleteResultItems: JQuery;
    private autocompleteSearchTimeout: NodeJS.Timer | null = null;
    private autocompleteLastProcessId: number | null = null;
    private isOpened: boolean = false;

    public static readonly defaultConfig: Partial<Config> = {
        placeholder: null,
        multiple: false,
        responseDataTransformer: null,
        responseDataItemLabelTransformer: null,
    };

    protected initialize(): void {
        this.refreshBindings();
        this.closeChoices(false);
        this.refreshInputValue();
        elementAttrChangeObserver.observe(this.element, ['readonly', 'disabled'], () => {
            if (this.isDisabledOrReadonly()) {
                this.disable();
                return;
            }
            this.enable();
        });
    }

    public refreshBindings(): void {
        this.input = this.$element.find('[data-nested-choice-select-input]');
        this.inputValue = this.$element.find('[data-nested-choice-select-input-value]');
        this.inputCaret = this.$element.find('[data-nested-choice-select-input-caret]');
        this.choices = this.$element.find('[data-nested-choice-select-choices]');
        this.choicesInputs = this.$element.find('[data-nested-choice-select-choice-input]');
        this.headers = this.$element.find('[data-nested-choice-select-choice-header]');
        this.autocompleteInput = this.$element.find('[data-nested-choice-select-choices-autocomplete-input]');
        this.autocompleteResultItems = this.$element.find('[data-nested-choice-select-choices-autocomplete-result-items]');
        this.headers
            .off(this.getScopedClickEvent())
            .on(this.getScopedClickEvent(), (event) => {
                event.stopPropagation();
                let header = $(event.target as HTMLDivElement);
                if (header.attr('data-nested-choice-select-choice-header') === undefined) {
                    header = header.closest('[data-nested-choice-select-choice-header]');
                }
                return this.onChoiceHeaderClick(header);
            });
        this.input
            .off(this.getScopedClickEvent())
            .on(this.getScopedClickEvent(), () => this.onInputClick());
        for (const event of ['input', 'cut', 'copy', 'paste']) {
            const scopedEvent = this.getScopedEvent(event);
            this.autocompleteInput.off(scopedEvent);
            this.autocompleteInput.on(scopedEvent, () => this.autocompleteOnInputChange());
        }
        this.autocompleteHideResultItems();
    }

    public getComponentName(): string {
        return 'NestedChoiceSelect';
    }

    private onInputClick(): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        this.toggleChoices();
    }

    public toggleChoices(animated: boolean = true): void {
        this.isOpened ? this.closeChoices(animated) : this.openChoices(animated);
    }

    public closeChoices(animated: boolean = true): void {
        this.isOpened = false;
        this.inputCaret.removeClass('fa-caret-up');
        this.inputCaret.addClass('fa-caret-down');
        animated ? this.choices.slideUp('normal') : this.choices.hide();
    }

    public openChoices(animated: boolean = true): void {
        this.isOpened = true;
        this.inputCaret.removeClass('fa-caret-down');
        this.inputCaret.addClass('fa-caret-up');
        animated ? this.choices.slideDown('normal') : this.choices.show();
    }

    public getSelectedValue(): SelectedValue | null {
        if (this.config.multiple) {
            throw new InvalidStateException('This method is available only for single mode.');
        }
        for (let i = 0; i < this.choicesInputs.length; i++) {
            const radio = $(this.choicesInputs[i]);
            if (radio.is(':checked')) {
                return new SelectedValue(radio.attr('value') as string, this.findLabelForInput(radio).text());
            }
        }
        return null;
    }

    public getSelectedValues(): SelectedValue[] {
        if (!this.config.multiple) {
            throw new InvalidStateException('This method is available only for multiple mode.');
        }
        let output = [];
        for (let i = 0; i < this.choicesInputs.length; i++) {
            const checkbox = $(this.choicesInputs[i]);
            if (checkbox.is(':checked')) {
                output.push(new SelectedValue(checkbox.attr('value') as string, this.findLabelForInput(checkbox).text()));
            }
        }
        return output;
    }

    private refreshInputValue(): void {
        const placeholder = this.config.placeholder;
        if (this.config.multiple) {
            const values = this.getSelectedValues();
            if (values.length === 0) {
                this.inputValue.text(String.isNullOrWhiteSpace(placeholder) ? '' : placeholder as string);
                return;
            }
            this.inputValue.text(values.map(u => u.label).join(', '));
            return;
        }
        const value = this.getSelectedValue();
        if (value === null) {
            this.inputValue.text(String.isNullOrWhiteSpace(placeholder) ? '' : placeholder as string);
            return;
        }
        this.inputValue.text(value.label);
    }

    private findLabelForInput(input: JQuery): JQuery {
        return this.$element.find('label[for=' + input.attr('id') + ']');
    }

    private onChoiceHeaderClick(header: JQuery, callback: (() => void) | null = null): boolean {
        const container = this.getChoiceContainer(header);
        const children = container.find('[data-nested-choice-select-choice-children]:first');
        const caret = header.find('[data-nested-choice-select-choice-caret]');
        const input = header.find('[data-nested-choice-select-choice-input]');
        const isHeaderSelected = header.hasClass('nested-choice-select__choice-header--is-selected');
        const isLastLevel = container.data('nestedChoiceSelectChoiceChildrenIsLastLevel') as boolean;
        const isLoading = container.data('nestedChoiceSelectChoiceIsLoading');
        const isLoaded = container.data('nestedChoiceSelectChoiceIsLoaded');
        const childrenUrl = container.data('nestedChoiceSelectChoiceChildrenUrl') as string | undefined;
        const isAsyncLoad = children.length > 0 && !isLastLevel && typeof childrenUrl === 'string';
        if (!isHeaderSelected) {
            if (!this.config.multiple && isLastLevel) {
                this.$element.find('.nested-choice-select__choice-header.nested-choice-select__choice-header--is-last-level.nested-choice-select__choice-header--is-selected').removeClass('nested-choice-select__choice-header--is-selected');
            }
            caret.removeClass('fa-caret-right');
            caret.addClass('fa-caret-down');
            header.addClass('nested-choice-select__choice-header--is-selected');
            input.prop('checked', true);
            if (isLastLevel) {
                this.refreshInputValue();
                if (!this.config.multiple) {
                    this.closeChoices();
                }
                callback !== null ? callback() : null;
                return false;
            }
            if (children.length === 0) {
                callback !== null ? callback() : null;
                return false;
            }
            if (!isAsyncLoad) {
                children.slideDown('normal');
                callback !== null ? callback() : null;
                return false;
            }
            if (isLoaded === true) {
                children.slideDown('normal');
                callback !== null ? callback() : null;
                return false;
            }
            if (isLoading === true) {
                callback !== null ? callback() : null;
                return false;
            }
            container.data('nestedChoiceSelectChoiceIsLoading', true);
            axios
                .request({
                    url: childrenUrl,
                    method: 'GET',
                })
                .then((response) => {
                    container.data('nestedChoiceSelectChoiceIsLoading', false);
                    container.data('nestedChoiceSelectChoiceIsLoaded', true);
                    const items = this.config.responseDataTransformer !== null
                        ? this.config.responseDataTransformer(response.data)
                        : response.data as NestedChoiceDataItem[];
                    for (let i = 0; i < items.length; i++) {
                        const itemWidget = this.createWidgetFromItem(items[i]);
                        children.append(itemWidget);
                    }
                    if (items.length > 0) {
                        this.refreshBindings();
                    }
                    children.slideDown('normal');
                    callback !== null ? callback() : null;
                })
                .catch((error) => {
                    container.data('nestedChoiceSelectChoiceIsLoading', false);
                    logger.log(error);
                    handleAxiosError(error);
                });
            return false;
        }
        caret.addClass('fa-caret-right');
        caret.removeClass('fa-caret-down');
        header.removeClass('nested-choice-select__choice-header--is-selected');
        input.prop('checked', false);
        if (isLastLevel) {
            this.refreshInputValue();
        }
        children.slideUp('normal');
        callback !== null ? callback() : null;
        return false;
    }

    private getChoiceContainer(subject: JQuery): JQuery {
        return subject.closest('[data-nested-choice-select-choice-container]');
    }

    private createWidgetFromItem(item: NestedChoiceDataItem): JQuery {
        const prototypeName = this.config.prototypeName;
        let html = item.isLastLevel ? this.config.lastLevelChildPrototype : this.config.childPrototype;
        html = html.replace(new RegExp(prototypeName + '__child_value__', 'g'), item.value.toString());
        html = html.replace(new RegExp(prototypeName + '__child_label__', 'g'), item.label);
        if (item.childrenUrl !== null) {
            html = html.replace(new RegExp(prototypeName + '__children_url__', 'g'), item.childrenUrl);
        }
        const widget = $(html);
        if (this.config.responseDataItemLabelTransformer !== null) {
            const labelWidget = widget.find('[data-nested-choice-select-choice-label]');
            const transformedLabel = this.config.responseDataItemLabelTransformer(item);
            if (typeof transformedLabel === 'string') {
                labelWidget.text(transformedLabel);
            } else if (typeof transformedLabel.length === 'number' && transformedLabel[0] instanceof HTMLElement) {
                labelWidget.html(transformedLabel[0].outerHTML);
            }
        }
        componentsLoader.load(widget);
        return widget;
    }

    public disable(): void {
        this.$element.addClass('nested-choice-select--is-disabled');
        if (this.$element.attr('disabled') === undefined) {
            this.$element.attr('disabled', 'disabled');
        }
        this.choicesInputs.prop('disabled', true);
        if (this.isOpened) {
            this.closeChoices();
        }
    }

    public enable(): void {
        this.$element.removeClass('nested-choice-select--is-disabled');
        if (this.$element.attr('disabled') !== undefined) {
            this.$element.removeAttr('disabled');
        }
        this.choicesInputs.prop('disabled', false);
    }

    private autocompleteOnInputChange(): void {
        if (this.autocompleteSearchTimeout !== null) {
            clearTimeout(this.autocompleteSearchTimeout);
        }
        const processId = Random.integer(0, 1000000);
        this.autocompleteLastProcessId = processId;
        const query = (this.autocompleteInput.val() as string).trim();
        if (query.length === 0) {
            this.autocompleteHideResultItems();
            return;
        }
        if (query.length < this.config.autocompleteInputMinLength) {
            this.autocompleteShowMessage(this.config.autocompleteInputMinLengthMessage);
            return;
        }
        this.autocompleteShowMessage(this.config.autocompleteSearchingMessage);
        this.autocompleteSearchTimeout = setTimeout(() => {
            const url = new URL(this.config.autocompleteUrl as string);
            url.searchParams.set('query', query);
            axios
                .request({
                    url: url.toString(),
                    method: 'GET',
                })
                .then((response) => {
                    if (this.autocompleteLastProcessId !== processId) {
                        return;
                    }
                    const items = this.config.autocompleteResponseDataTransformer !== null
                        ? this.config.autocompleteResponseDataTransformer(response.data)
                        : response.data as NestedChoiceAutocompleteItem[];
                    if (items.length === 0) {
                        this.autocompleteShowMessage(this.config.autocompleteEmptyResultMessage);
                        return;
                    }
                    this.autocompleteResultItems.empty();
                    this.autocompleteResultItems.show();
                    for (let i = 0; i < items.length; i++) {
                        const itemWidget = this.autocompleteCreateWidgetFromItem(items[i]);
                        this.autocompleteResultItems.append(itemWidget);
                    }
                })
                .catch((error) => {
                    if (this.autocompleteLastProcessId !== processId) {
                        return;
                    }
                    this.autocompleteHideResultItems();
                    logger.log(error);
                    handleAxiosError(error);
                });
        }, 500);
    }

    private autocompleteCreateWidgetFromItem(item: NestedChoiceAutocompleteItem): JQuery {
        let html = this.config.autocompleteResultItemTemplate;
        html = html.replace(new RegExp('__label__', 'g'), item.label);
        const widget = $(html);
        if (this.config.autocompleteResponseDataItemLabelTransformer !== null) {
            const labelWidget = widget.find('[data-nested-choice-select-autocomplete-result-item-label]');
            const transformedLabel = this.config.autocompleteResponseDataItemLabelTransformer(item);
            if (typeof transformedLabel === 'string') {
                labelWidget.text(transformedLabel);
            } else if (typeof transformedLabel.length === 'number' && transformedLabel[0] instanceof HTMLElement) {
                labelWidget.html(transformedLabel[0].outerHTML);
            }
        }
        const clickEvent = this.getScopedClickEvent();
        widget.off(clickEvent);
        widget.on(clickEvent, () => this.autocompleteOnResultItemSelect(item));
        return widget;
    }

    private autocompleteOnResultItemSelect(item: NestedChoiceAutocompleteItem): void {
        this.autocompleteResultItems.hide();
        const path = item.path.split('.');
        const selectItem = () => {
            if (path.length === 0) {
                return;
            }
            const value = path.shift();
            const container = this.$element.find('[data-nested-choice-select-choice-container-value="' + value + '"]');
            if (container.length === 0) {
                throw new InvalidStateException('Could not find choice container by value: ' + value);
            }
            if (path.length === 0) {
                container[0].scrollIntoView({
                    behavior: 'auto',
                    block: 'nearest',
                    inline: 'start',
                });
            }
            const header = container.find('[data-nested-choice-select-choice-header]:first');
            const isHeaderSelected = header.hasClass('nested-choice-select__choice-header--is-selected');
            if (!isHeaderSelected) {
                this.onChoiceHeaderClick(header, () => selectItem());
                return;
            }
            selectItem();
        };
        selectItem();
    }

    private autocompleteHideResultItems(): void {
        this.autocompleteResultItems.hide();
        this.autocompleteResultItems.empty();
    }

    private autocompleteShowMessage(message: string): void {
        this.autocompleteResultItems.empty();
        this.autocompleteResultItems.show();
        let html = this.config.autocompleteResultItemMessageTemplate;
        html = html.replace(new RegExp('__message__', 'g'), message);
        this.autocompleteResultItems.html(html);
    }
}

export type Config = {
    placeholder: string | null;
    multiple: boolean;
    responseDataTransformer: ResponseDataTransformer | null;
    responseDataItemLabelTransformer: ResponseDataItemLabelTransformer | null;
    childPrototype: string;
    lastLevelChildPrototype: string;
    prototypeName: string;
    autocompleteEnable: boolean;
    autocompleteEmptyResultMessage: string;
    autocompleteSearchingMessage: string;
    autocompleteInputMinLengthMessage: string;
    autocompleteInputMinLength: number;
    autocompleteUrl: string | null;
    autocompleteResultItemMessageTemplate: string;
    autocompleteResultItemTemplate: string;
    autocompleteResponseDataTransformer: AutocompleteResponseDataTransformer | null;
    autocompleteResponseDataItemLabelTransformer: AutocompleteResponseDataItemLabelTransformer | null;
}

type ResponseDataItemLabelTransformer = (item: NestedChoiceDataItem) => string | JQuery;

type ResponseDataTransformer<T = any> = (response: T) => NestedChoiceDataItem[];

type AutocompleteResponseDataTransformer<T = any> = (response: T) => NestedChoiceAutocompleteItem[];

type AutocompleteResponseDataItemLabelTransformer = (item: NestedChoiceAutocompleteItem) => string | JQuery;

type NestedChoiceDataItem = {
    label: string;
    isLastLevel: boolean;
    childrenUrl: string | null;
    value: string | number;
}

type NestedChoiceAutocompleteItem = {
    label: string;
    value: string | number;
    path: string;
}

class SelectedValue
{
    private readonly _value: string;
    private readonly _label: string;

    constructor(value: string, label: string) {
        this._value = value;
        this._label = label;
    }

    public get value(): string {
        return this._value;
    }

    public get label(): string {
        return this._label;
    }
}
