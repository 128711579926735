import {Controller} from '@hotwired/stimulus';

export default class NotificationsMenuItemController extends Controller
{
    private submenuTarget!: HTMLElement;
    private isOpened: boolean;
    private unreadCountValue!: number;
    private unreadCountTarget!: HTMLElement;
    private unreadClass!: string;

    static targets = ['submenu', 'unreadCount'];
    static values = {unreadCount: Number};
    static classes = ['unread'];

    public connect() {
        const styles = getComputedStyle(this.submenuTarget);
        this.isOpened = styles.display !== 'none';
        window.addEventListener('notification:read', () => this.handleNotificationRead());
        window.addEventListener('click', (event) => this.handleGlobalClick(event));
    }

    private handleGlobalClick(event: MouseEvent): void {
        if (!this.isOpened) {
            return;
        }
        const eventTarget = event.target as HTMLElement;
        if (this.element === eventTarget || this.element.contains(eventTarget)) {
            return;
        }
        if (this.submenuTarget === eventTarget || this.submenuTarget.contains(eventTarget)) {
            return;
        }
        this.closeSubmenu();
    }

    private handleNotificationRead(): void {
        if (this.unreadCountValue === 0) {
            return;
        }
        this.unreadCountValue--;
        this.unreadCountTarget.innerHTML = this.unreadCountValue.toString();
        if (this.unreadCountValue === 0) {
            this.unreadCountTarget.remove();
            this.element.classList.remove(this.unreadClass);
        }
    }

    public toggleSubmenu(): void {
        this.isOpened ? this.closeSubmenu() : this.openSubmenu();
    }

    private openSubmenu(): void {
        if (this.isOpened) {
            return;
        }
        this.isOpened = true;
        $(this.submenuTarget).slideDown();
    }

    private closeSubmenu(): void {
        if (!this.isOpened) {
            return;
        }
        this.isOpened = false;
        $(this.submenuTarget).slideUp();
    }
}
