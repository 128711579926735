import 'toastr/toastr.scss';
import * as toastr from 'toastr';

class Messenger
{
    public success(message: string): void {
        toastr.success(message);
    }

    public error(message: string): void {
        toastr.error(message);
    }
}

export const messenger = new Messenger();
