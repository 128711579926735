import Component from 'app/components/component';
import InvalidStateException from 'app/errors/invalid-state-exception';
import Modal, {ModalEvents} from 'app/components/modal';
import Values from 'app/utils/values';
import {messenger} from 'app/utils/messenger';
import Select from 'app/components/select';

export default class TextSelect extends Component<Config, HTMLSelectElement>
{

    public getComponentName(): string {
        return 'TextSelect';
    }

    protected initialize() {
        this.refreshBindings();
    }

    public refreshBindings() {
        const changeEvent = this.getScopedChangeEvent();
        Select.getOne(this.$element);
        this.$element.off(changeEvent);
        this.$element.on(changeEvent, () => this.handleValueChanged());
    }

    private handleValueChanged(): void {
        const value = this.getValue();
        if (String.isNullOrWhiteSpace(value)) {
            return;
        }
        const option = this.findOptionByValue(value);
        if (option === null) {
            throw new InvalidStateException('Could not find option element by value ' + value);
        }
        if (typeof option.attr('data-text-select-other-option') === 'undefined') {
            return;
        }
        this.openOtherValueInputModal();
    }

    private openOtherValueInputModal(): void {
        const modalEl = $(this.config.modalTemplate);
        $('body').append(modalEl);
        const modal = Modal.getOne(modalEl) as Modal;
        const input = modalEl.find('[data-text-select-input]');
        const confirmBtn = modalEl.find('[data-text-select-confirm-btn]');
        modal.addEventListener(ModalEvents.Hidden, () => {
            modalEl.remove();
            const inputVal = input.val() as string;
            Values.isEmpty(inputVal) ? this.setValue(this.getFallbackValue()) : this.setValue(inputVal);
        });
        confirmBtn.on(this.getScopedClickEvent(), () => {
            const inputVal = input.val() as string;
            if (Values.isEmpty(inputVal)) {
                messenger.error(this.config.emptyValueMessage);
                return;
            }
            modal.hideAndRemove();
        });
        modal.show();
    }

    private findOptionByValue(value: string | null): null | JQuery<HTMLOptionElement> {
        value = value ?? '';
        const options = this.$element.find('option');
        for (let i = 0; i < options.length; i++) {
            const option = $(options[i]);
            if (option.attr('value') === value) {
                return option;
            }
        }
        return null;
    }

    public getValue(): string | null {
        return this.$element.val() as string | null;
    }

    private setValue(value: string): void {
        const option = this.findOptionByValue(value);
        if (option === null) {
            const options = this.$element.find('option');
            $(options[options.length - 1]).before(`<option value="${value}">${value}</option>`);
        }
        this.$element.val(value);
        this.$element.trigger('change');
    }

    private getFallbackValue(): string {
        return $(this.$element.find('option:first-child')).attr('value') as string;
    }
}

type Config = {
    modalTemplate: string;
    emptyValueMessage: string;
}
