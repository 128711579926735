import NestedChoiceSelect, {Config as ParentConfig} from 'app/components/nested-choice-select';
import InvalidArgumentException from 'app/errors/invalid-argument-exception';
import InvalidStateException from 'app/errors/invalid-state-exception';

export default class EconomicActivityFieldNestedChoiceSelect extends NestedChoiceSelect
{
    private registry: { [_key: number]: EconomicActivityField } = {};

    private static readonly maxDepth = 1;

    constructor(element: HTMLDivElement, config: Partial<Config> | null = null) {
        if (config === null) {
            config = {} as Partial<Config>;
        }
        if (typeof config.responseDataTransformer !== 'function') {
            config.responseDataTransformer = (response) => {
                const config = this.config as Config;
                const field = response as EconomicActivityField;
                this.saveToRegistry(field);
                const output = [];
                if (Array.isArray(field.children) && field.children.length > 0) {
                    for (let i = 0; i < field.children.length; i++) {
                        const child = field.children[i];
                        this.saveToRegistry(child);
                        output.push({
                            label: this.createLabel(child),
                            isLastLevel: child.childrenCount === 0 || child.depth === EconomicActivityFieldNestedChoiceSelect.maxDepth,
                            childrenUrl: config.economicActivityFieldUrlTemplate.replace('__id__', child.id.toString()),
                            value: child.id,
                        });
                    }
                }
                return output;
            };
        }
        if (typeof config.responseDataItemLabelTransformer !== 'function') {
            config.responseDataItemLabelTransformer = (item) => {
                const field = this.loadFromRegistry(item.value as number);
                return this.createWidgetLabel(field);
            };
        }
        if (typeof config.autocompleteResponseDataTransformer !== 'function') {
            config.autocompleteResponseDataTransformer = (response) => {
                const fields = response as EconomicActivityField[];
                const output = [];
                for (const field of fields) {
                    if (typeof field.path !== 'string') {
                        throw new InvalidStateException('Path property should be string.');
                    }
                    this.saveToRegistry(field);
                    output.push({
                        label: this.createLabel(field),
                        path: field.path,
                        value: field.id,
                    });
                }
                return output;
            };
        }
        if (typeof config.autocompleteResponseDataItemLabelTransformer !== 'function') {
            config.autocompleteResponseDataItemLabelTransformer = (item) => {
                const field = this.loadFromRegistry(item.value as number);
                return this.createWidgetLabel(field);
            };
        }
        super(element, config);
    }

    private createLabel(field: EconomicActivityField): string {
        const trans = field.translations[APP_CONFIG.common.locale];
        return String.isNullOrWhiteSpace(trans.mark) ? trans.name : trans.mark + ' - ' + trans.name;
    }

    private createWidgetLabel(field: EconomicActivityField): JQuery | string {
        const trans = field.translations[APP_CONFIG.common.locale];
        if (String.isNullOrWhiteSpace(trans.mark)) {
            return trans.name;
        }
        return $(`<span><strong>${trans.mark}</strong> - ${trans.name}</span>`);
    }

    private saveToRegistry(field: EconomicActivityField): void {
        const hit = this.registry[field.id];
        if (typeof hit === 'undefined') {
            this.registry[field.id] = field;
            return;
        }
        this.registry[field.id] = {...hit, ...field};
    }

    private loadFromRegistry(id: number): EconomicActivityField {
        const hit = this.registry[id];
        if (typeof hit === 'undefined') {
            throw new InvalidArgumentException('Could not find item in registry with id ' + id);
        }
        return hit;
    }
}

type Config = ParentConfig & {
    economicActivityFieldUrlTemplate: string;
};

type EconomicActivityField = {
    id: number;
    childrenCount: number;
    country: string;
    translations: { [_key: string]: EconomicActivityFieldTranslation };
    children: undefined | EconomicActivityField[];
    depth?: number;
    path?: string;
}

type EconomicActivityFieldTranslation = {
    id: number;
    locale: string;
    mark: string | null;
    name: string;
}
