import $ from 'jquery';
import NotImplementedException from 'app/errors/not-implemented-exception';
import {logger} from 'app/service/logger';
import Event = JQuery.Event;

export default abstract class Component<TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>
{
    private readonly _element: TElement;
    private readonly _$element: JQuery;
    private readonly _config: TConfig;
    private readonly _eventListeners: EventListeners = {};

    public static readonly defaultConfig: Object = {};
    private static readonly instances: InstancesRegistry = {};

    protected constructor(element: TElement, config: Partial<TConfig> | null = null, callInitialize: boolean = true) {
        this._element = element;
        this._$element = $(element);
        this._config = this.assembleConfig(config);
        if (callInitialize) {
            this.initialize();
        }
    }

    protected initialize(): void {
    }

    public refreshBindings(): void {
        throw new NotImplementedException('Refresh bindings is not implemented for component ' + this.getComponentName());
    }

    protected getScopedEvent(event: string): string {
        return event + '.' + this.getComponentName().toLowerCase();
    }

    protected getScopedChangeEvent(): string {
        return this.getScopedEvent('change');
    }

    protected getScopedFocusEvent(): string {
        return this.getScopedEvent('focus');
    }

    protected getScopedFocusOutEvent(): string {
        return this.getScopedEvent('focusout');
    }

    protected getScopedSubmitEvent(): string {
        return this.getScopedEvent('submit');
    }

    protected getScopedClickEvent(): string {
        return this.getScopedEvent('click');
    }

    private assembleConfig(passedConfig: Partial<TConfig> | null): TConfig {
        const self = this.constructor as typeof Component;
        const defaultConfig = self.defaultConfig as TConfig;
        const dataAttributes = this._$element.data() as TConfig;
        const config = {} as TConfig;
        const componentName = this.getComponentName();
        for (const configToMerge of [passedConfig, dataAttributes, defaultConfig]) {
            if (configToMerge === null) {
                continue;
            }
            for (const property in configToMerge) {
                let configKey = property;
                if (configKey.startsWith(componentName.firstToLower())) {
                    configKey = configKey.substring(componentName.length).firstToLower() as Extract<keyof TConfig, string>;
                    if (String.isNullOrWhiteSpace(configKey)) {
                        continue;
                    }
                }
                if (
                    Object.prototype.hasOwnProperty.call(configToMerge, property)
                    && config[configKey] === undefined
                ) {
                    config[configKey as Extract<keyof TConfig, string>] = configToMerge[property] as TConfig[Extract<keyof TConfig, string>];
                }
            }
        }
        return config;
    }

    public setConfigItem<T = TConfig[keyof TConfig]>(key: keyof TConfig, value: T): void {
        this._config[key] = value as TConfig[keyof TConfig];
    }

    public setConfig(config: Partial<TConfig>): void {
        for (let property in config) {
            if (!Object.prototype.hasOwnProperty.call(config, property)) {
                continue;
            }
            this._config[property] = config[property] as TConfig[Extract<keyof TConfig, string>];
        }
    }

    public abstract getComponentName(): string;

    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(element: TElement, config?: TConfig): TComponent | null;
    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(selector: string, config?: TConfig): TComponent | null;
    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(element: JQuery, config?: TConfig): TComponent | null;
    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(elements: TElement[], config?: TConfig): TComponent | null;
    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(elements: NodeListOf<TElement>, config?: TConfig): TComponent | null;
    public static getOne<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(
        this: new (element: TElement, config: TConfig | null) => TComponent,
        parameter: any,
        config: TConfig | null = null,
    ): TComponent | null {
        let subject: TElement | null = null;
        if (parameter instanceof HTMLElement) {
            subject = parameter as TElement;
        } else if (typeof parameter === 'string') {
            const element = document.querySelector<TElement>(parameter);
            if (element !== null) {
                subject = element;
            }
        } else if (typeof parameter['length'] === 'number') {
            if (parameter.length > 0) {
                subject = parameter[0] as TElement;
            }
        } else {
            throw new NotImplementedException();
        }
        if (subject === null) {
            return null;
        }
        const self = this.prototype.constructor as typeof Component;
        const componentName = this.prototype.getComponentName.call() as string;
        if (typeof self.instances[componentName] === 'undefined') {
            self.instances[componentName] = new WeakMap<HTMLElement, Object>();
        }
        const instances = self.instances[componentName];
        let instance = instances.get(subject) as TComponent | undefined;
        if (instance === undefined) {
            instance = new this(subject, config) as TComponent;
            instances.set(subject, instance as Object);
        }
        return instance;
    }

    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(element: TElement, config?: TConfig): TComponent | null;
    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(selector: string, config?: TConfig): TComponent | null;
    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(element: JQuery, config?: TConfig): TComponent | null;
    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(elements: TElement[], config?: TConfig): TComponent | null;
    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(elements: NodeListOf<TElement>, config?: TConfig): TComponent | null;
    public static getAll<TComponent = Component, TConfig extends Object = EmptyConfig, TElement extends HTMLElement = HTMLElement>(
        this: new (element: TElement, config: TConfig | null) => TComponent,
        parameter: any,
        config: TConfig | null = null,
    ): TComponent[] {
        const subject: TElement[] = [];
        if (parameter instanceof HTMLElement) {
            subject.push(subject as unknown as TElement);
        } else if (typeof parameter === 'string') {
            const elements = document.querySelectorAll<TElement>(parameter);
            for (let i = 0; i < elements.length; i++) {
                subject.push(elements[i]);
            }
        } else if (typeof parameter['length'] === 'number') {
            for (let i = 0; i < parameter.length; i++) {
                subject.push(parameter[i]);
            }
        } else {
            throw new NotImplementedException();
        }
        const output: TComponent[] = [];
        const self = this.prototype.constructor as typeof Component;
        for (let i = 0; i < subject.length; i++) {
            // @ts-ignore
            output.push(self.getOne(subject[i] as HTMLElement, config) as TComponent);
        }
        return output;
    }

    get element(): TElement {
        return this._element;
    }

    get $element(): JQuery {
        return this._$element;
    }

    get config(): TConfig {
        return this._config;
    }

    public addEventListener(eventName: string, eventListener: EventListener): void {
        if (!Array.isArray(this._eventListeners[eventName])) {
            this._eventListeners[eventName] = [];
        }
        this._eventListeners[eventName].push(eventListener);
    }

    public removeEventListener(eventName: string, eventListener: EventListener): void {
        if (!Array.isArray(this._eventListeners[eventName])) {
            return;
        }
        this._eventListeners[eventName].remove(eventListener);
    }

    public triggerEvent(eventName: string, event: Event | null = null, data: object | null = null): void {
        const eventData: ComponentEventData = {
            component: this,
            event: event,
            data: data,
        };
        this.$element.trigger(eventName, eventData);
        if (!Array.isArray(this._eventListeners[eventName])) {
            return;
        }
        try {
            this._eventListeners[eventName].forEach((listener) => {
                listener(eventData);
            });
        } catch (e) {
            logger.log(e);
        }
    }

    public isReadOnly(): boolean {
        return this.$element.attr('readonly') !== undefined;
    }

    public isDisabled(): boolean {
        return this.$element.attr('disabled') !== undefined;
    }

    public isDisabledOrReadonly(): boolean {
        return this.isReadOnly() || this.isDisabled();
    }
}

export type EmptyConfig = {};

type EventListeners = {
    [key: string]: EventListener[];
};

export type ComponentEventData<C extends Component = Component, T = any, E = Event> = {
    component: C,
    event: E | null,
    data: T | null,
}

type EventListener = (data: object) => void;

type InstancesRegistry = { [key: string]: WeakMap<HTMLElement, Object> };
