import {logger} from 'app/service/logger';
import {messenger} from 'app/utils/messenger';
import {Controller} from '@hotwired/stimulus';

export default class NotificationsReaderListItemController extends Controller
{
    private titleTarget!: HTMLElement;
    private annotationTarget!: HTMLElement;
    private dateTarget!: HTMLElement;
    private contentTarget!: HTMLElement;
    private setAsReadUrlValue!: string;
    private unreadClass!: string;
    private isUnreadValue!: boolean;
    private idValue!: number;

    static values = {setAsReadUrl: String, isUnread: Boolean, id: Number};
    static targets = ['title', 'annotation', 'date', 'content'];
    static classes = ['unread'];

    public async click(): Promise<void> {
        document.dispatchEvent(new CustomEvent<ClickEventDetail>(
            'notifications-reader-list-item:click',
            {
                detail: {
                    id: this.idValue,
                    title: this.titleTarget.innerHTML,
                    annotation: this.annotationTarget.innerHTML,
                    date: this.dateTarget.innerHTML,
                    description: this.contentTarget.innerHTML,
                },
                bubbles: true,
                cancelable: true,
            },
        ));
        if (!this.isUnreadValue) {
            return;
        }
        try {
            const response = await fetch(this.setAsReadUrlValue, {method: 'PUT'});
            const json: Response = await response.json();
            if (json.status !== 'success') {
                throw new Error(json.message ?? APP_CONFIG.messages.common.error);
            }
            this.element.classList.remove(this.unreadClass);
            this.isUnreadValue = false;
            document.dispatchEvent(new CustomEvent<NotificationReadEventDetail>(
                'notification:read',
                {
                    detail: {
                        id: this.idValue,
                    },
                    bubbles: true,
                    cancelable: true,
                },
            ));
        } catch (error) {
            logger.log(error);
            messenger.error(APP_CONFIG.messages.common.error);
        }
    }
}

type Response = {
    status: string;
    message?: string;
}

export type ClickEventDetail = {
    id: number;
    title: string;
    annotation: string;
    date: string;
    description: string;
}

export type NotificationReadEventDetail = {
    id: number;
};
