import Component from 'app/components/component';

export default class AddressInput extends Component<Config, HTMLDivElement>
{
    private zipCode: JQuery;
    private zipCodeLabel: JQuery;
    private country: JQuery;

    public static readonly defaultConfig: Partial<Config> = {
        zipCodeCountries: [],
    };

    protected initialize() {
        this.refreshBindings();
    }

    public getComponentName(): string {
        return 'AddressInput';
    }

    public refreshBindings() {
        const changeEvent = this.getScopedChangeEvent();
        this.zipCode = this.$element.find('[data-address-input-zip-code]');
        this.zipCodeLabel = this.$element.find('[data-address-input-zip-code-label]');
        this.country = this.$element.find('[data-address-input-country]');
        this.country.off(changeEvent);
        this.country.on(changeEvent, () => this.onCountryChange());
        this.country.trigger(changeEvent);
    }

    private onCountryChange(): void {
        const selectedCountry = this.country.val() as string | null;
        selectedCountry === null || this.config.zipCodeCountries.indexOf(selectedCountry) < 0
            ? this.setZipCodeOptional()
            : this.setZipCodeRequired();
    }

    private setZipCodeRequired(): void {
        this.zipCodeLabel.addClass('required');
        this.zipCode.attr('required', 'required');
    }

    private setZipCodeOptional(): void {
        this.zipCodeLabel.removeClass('required');
        this.zipCode.removeAttr('required');
    }
}

type Config = {
    zipCodeCountries: string[];
};
