class RequiredAttrInputToggler
{
    private static IS_REQUIRED_KEY = '__required_attr_input_toggler_is_required';
    private static IS_REGISTERED_KEY = '__required_attr_input_toggler_is_registered';

    public register(element: JQuery): void {
        element.findWithSelf(':input').each((_, input) => {
            const $input = $(input);
            if ($input.data(RequiredAttrInputToggler.IS_REGISTERED_KEY) === true) {
                return;
            }
            $input.data(RequiredAttrInputToggler.IS_REGISTERED_KEY, true)
            if ($input.attr('required') !== undefined) {
                $input.data(RequiredAttrInputToggler.IS_REQUIRED_KEY, true);
            }
        });
    }

    public setAsRequired(element: JQuery): void {
        element.findWithSelf(':input').each((_, input) => {
            const $input = $(input);
            if ($input.data(RequiredAttrInputToggler.IS_REQUIRED_KEY) === true) {
                $input.attr('required', 'required');
                return;
            }
        });
    }

    public setAsNotRequired(element: JQuery): void {
        element.findWithSelf(':input').each((_, input) => {
            $(input).removeAttr('required');
        });
    }
}

const requiredAttrInputToggler = new RequiredAttrInputToggler();

export default requiredAttrInputToggler;
