import $ from 'jquery';
import NotImplementedException from 'app/errors/not-implemented-exception';
import CopyToClipboardButton from '../components/copy-to-clipboard-button';
import Datagrid from 'app/components/datagrid';
import AsyncForm from '../components/async-form';
import Select from 'app/components/select';
import FileUpload from 'app/components/file-upload';
import Modal from 'app/components/modal';
import IdentityCheck from 'app/components/identity-check';
import Tab from 'app/components/tab';
// import FrontendNavigation from 'app/components/frontend-navigation';
import MobileNavigation from 'app/components/mobile-navigation';
import AdminNavigation from 'app/components/admin-navigation';
import Dropdown from 'app/components/dropdown';
import AsyncContent from 'app/components/async-content';
import DateTimePicker from 'app/components/date-time-picker';
import RsFwdForm from 'app/components/forms/rs-fwd-form';
import NestedChoiceSelect from 'app/components/nested-choice-select';
import EconomicActivityFieldNestedChoiceSelect from 'app/components/economic-activity-field-nested-choice-select';
import CodeHighlighter from 'app/components/code-highlighter';
import VerificationCode from 'app/components/verification-code';
import RsFwdSignatureProcessForm from 'app/components/forms/rs-fwd-signature-process-form';
import DocumentationList from 'app/components/documentation-list';
import ScrollToId from 'app/components/scroll-to-id';
import FormHelp from 'app/components/form-help';
import PasswordInput from 'app/components/password-input';
import TextSelect from 'app/components/text-select';
import EddQuestionnaireForm from 'app/components/forms/edd-questionnaire-form';
import ConfirmUrlAction from 'app/components/confirm-url-action';
import AddressInput from 'app/components/address-input';
import RsFwdManualSignForm from 'app/components/forms/rs-fwd-manual-sign-form';
import PersistedEventFilterForm from 'app/components/forms/persisted-event-filter-form';
import DiffResult from 'app/components/diff-result';

class ComponentsLoader
{
    public load(): void;
    public load(target: HTMLElement): void;
    public load(target: JQuery): void;
    public load(parameter?: any): void {
        let target: JQuery | null = null;
        if (typeof parameter === 'undefined') {
            target = $('body');
        } else if (parameter instanceof HTMLElement) {
            target = $(parameter);
        } else if (parameter instanceof jQuery) {
            target = parameter as JQuery;
        }
        if (target === null) {
            throw new NotImplementedException();
        }
        // here register common components for admin and public
        CopyToClipboardButton.getAll(target.findWithSelf('[data-copy-to-clipboard-button]'));
        Datagrid.getAll(target.findWithSelf('[data-datagrid-component]'));
        AsyncForm.getAll(target.findWithSelf('[data-async-form]'));
        Select.getAll(target.findWithSelf('[data-select]'));
        FileUpload.getAll(target.findWithSelf('[data-file-upload]'));
        IdentityCheck.getAll(target.findWithSelf('[data-identity-check]'));
        Tab.getAll(target.findWithSelf('[data-tab]'));
        // FrontendNavigation.getAll(target.findWithSelf('[data-frontend-navigation]'));
        MobileNavigation.getAll(target.findWithSelf('[data-mobile-nav]'));
        ScrollToId.getAll(target.findWithSelf('[data-scroll-id]'));
        DocumentationList.getAll(target.findWithSelf('[data-documentation-list]'));
        AdminNavigation.getAll(target.findWithSelf('[data-admin-layout]'));
        Dropdown.getAll(target.findWithSelf('[data-dropdown]'));
        AsyncContent.getAll(target.findWithSelf('[data-async-content]'));
        DateTimePicker.getAll(target.findWithSelf('[data-date-time-picker]'));
        RsFwdForm.getAll(target.findWithSelf('[data-rs-fwd-form]'));
        NestedChoiceSelect.getAll(target.findWithSelf('[data-nested-choice-select]'));
        EconomicActivityFieldNestedChoiceSelect.getAll(target.findWithSelf('[data-economic-activity-field-nested-select]'));
        CodeHighlighter.getAll(target.findWithSelf('[data-code-highlighter]'));
        VerificationCode.getAll(target.findWithSelf('[data-verification-code]'));
        RsFwdSignatureProcessForm.getAll(target.findWithSelf('[data-rs-fwd-signature-process-form]'));
        FormHelp.getAll(target.findWithSelf('[data-form-help]'));
        PasswordInput.getAll(target.findWithSelf('[data-password-input]'));
        TextSelect.getAll(target.findWithSelf('[data-text-select]'));
        EddQuestionnaireForm.getAll(target.findWithSelf('[data-edd-questionnaire-form]'));
        ConfirmUrlAction.getAll(target.findWithSelf('[data-confirm-url-action]'));
        AddressInput.getAll(target.findWithSelf('[data-address-input]'));
        RsFwdManualSignForm.getAll(target.findWithSelf('[data-rs-fwd-manual-sign-document-form]'));
        PersistedEventFilterForm.getAll(target.findWithSelf('[data-persisted-event-filter-form]'));
        DiffResult.getAll(target.findWithSelf('[data-diff-result]'));
        Modal.getAll(target.findWithSelf('[data-modal]')); // has to be last
    }
}

export const componentsLoader = new ComponentsLoader();
