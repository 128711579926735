import $ from 'jquery';

export default class Values
{
    public static isEmpty(value: any): boolean {
        if (value === undefined || value === null) {
            return true;
        }
        if (typeof value === 'string' && String.isNullOrWhiteSpace(value)) {
            return true;
        }
        if (Array.isArray(value) && value.length === 0) {
            return true;
        }
        if (typeof value === 'object' && $.isEmptyObject(value)) {
            return true;
        }
        return false;
    }
}
