import Component from 'app/components/component';
import requiredAttrInputToggler from 'app/utils/required-attr-input-toggler';

export default class EddQuestionnaireForm extends Component
{
    private hasBrickAndMortarShop: JQuery;
    private brickAndMortarShopAddressContainer: JQuery;
    private hasEshop: JQuery;
    private eshopUrlContainer: JQuery;

    protected initialize() {
        this.refreshBindings();
    }

    public getComponentName(): string {
        return 'EddQuestionnaireForm';
    }

    public refreshBindings() {
        this.hasBrickAndMortarShop = this.$element.find('[data-edd-questionnaire-form-has-brick-and-mortar-shop]');
        this.brickAndMortarShopAddressContainer = this.$element.find('[data-edd-questionnaire-form-brick-and-mortar-shop-container]');
        this.hasEshop = this.$element.find('[data-edd-questionnaire-form-has-eshop]');
        this.eshopUrlContainer = this.$element.find('[data-edd-questionnaire-form-eshop-url-container]');
        const changeEvent = this.getScopedChangeEvent();
        this.hasBrickAndMortarShop
            .off(changeEvent)
            .on(changeEvent, (event) => this.onHasBrickAndMortarShopChange(event as JQuery.ChangeEvent))
            .trigger(changeEvent);
        this.hasEshop
            .off(changeEvent)
            .on(changeEvent, (event) => this.onHasEshopChange(event as JQuery.ChangeEvent))
            .trigger(changeEvent);
    }

    public onHasBrickAndMortarShopChange(event: JQuery.ChangeEvent): void {
        const radio = $(event.target as HTMLElement);
        if (!radio.is(':checked')) {
            return;
        }
        const isSelected = radio.val() === '1';
        requiredAttrInputToggler.register(this.brickAndMortarShopAddressContainer);
        if (isSelected) {
            requiredAttrInputToggler.setAsRequired(this.brickAndMortarShopAddressContainer);
            this.brickAndMortarShopAddressContainer.show();
            return;
        }
        requiredAttrInputToggler.setAsNotRequired(this.brickAndMortarShopAddressContainer);
        this.brickAndMortarShopAddressContainer.hide();
    }

    public onHasEshopChange(event: JQuery.ChangeEvent): void {
        const radio = $(event.target as HTMLElement);
        if (!radio.is(':checked')) {
            return;
        }
        const isSelected = radio.val() === '1';
        requiredAttrInputToggler.register(this.eshopUrlContainer);
        if (isSelected) {
            requiredAttrInputToggler.setAsRequired(this.eshopUrlContainer);
            this.eshopUrlContainer.show();
            return;
        }
        requiredAttrInputToggler.setAsNotRequired(this.eshopUrlContainer);
        this.eshopUrlContainer.hide();
    }
}
