import Component from 'app/components/component';
import Modal from 'app/components/modal';
import axios, {AxiosResponse} from 'axios';
import {logger} from 'app/service/logger';
import {componentsLoader} from 'app/service/components-loader';
import AsyncContent, {AsyncContentEvents} from 'app/components/async-content';
import AsyncForm, {AsyncFormEvents} from 'app/components/async-form';
import * as Onfido from 'onfido-sdk-ui';
import {
    LocaleConfig as OnfidoLocaleConfig,
    PublicStepConfig as OnfidoPublicStepConfig,
    PublicStepTypes as OnfidoPublicStepTypes,
    SdkHandle as OnfidoSdkHandle,
    SupportedLanguages as OnfidoSupportedLanguages,
    UICustomizationOptions as OnfidoUICustomizationOptions,
} from 'onfido-sdk-ui';
import onfidoLanguageCs from 'app/translations/onfido.cs.json';
import onfidoLanguageEn from 'app/translations/onfido.en.json';
import FormHelp from 'app/components/form-help';
import {Colors} from 'app/enum/colors';

export default class IdentityCheck extends Component<Config, HTMLDivElement>
{
    private idInput: JQuery;
    private openModalBtn: JQuery;
    private modal: Modal | null = null;
    private _isVerified: boolean;
    private verifiedStatusMonitorInterval: NodeJS.Timer | null = null;
    private onfidoCheckStatusMonitorInterval: NodeJS.Timer | null = null;
    private bankIdCheckStatusMonitorInterval: NodeJS.Timer | null = null;
    private form: JQuery;
    private formOnfido: OnfidoSdkHandle | null = null;
    private formButtonsContainer: JQuery;
    private formBackBtn: JQuery;
    private formSubmitBtn: JQuery;
    private formOnfidoMount: JQuery;
    private formOnfidoDocumentFrontId: JQuery;
    private formOnfidoDocumentBackId: JQuery;
    private formBankIdAuthenticateBtn: JQuery;
    private loadingAnimation: JQuery;
    private formErrorContainer: JQuery;
    private formErrorText: JQuery;

    constructor(element: HTMLDivElement, config: Partial<Config>) {
        super(element, config, false);
        this._isVerified = this.config.isVerified;
        this.initialize();
    }

    public getComponentName(): string {
        return 'IdentityCheck';
    }

    public get isVerified(): boolean {
        return this._isVerified;
    }

    private set isVerified(value: boolean) {
        if (value) {
            this.openModalBtn.html(this.config.verifiedButtonContent);
            this.openModalBtn.attr('disabled', 'disabled');
            this.closeModalAndResetWidget();
            this.triggerEvent(IdentityCheckEvents.VERIFIED);
        }
        this._isVerified = value;
    }

    protected initialize() {
        this.refreshBindings();
    }

    public refreshBindings() {
        this.startVerifiedStatusMonitor();
        this.idInput = this.$element.findWithSelf('[data-identity-check-id-input]');
        this.openModalBtn = this.$element.findWithSelf('[data-identity-check-open-modal-button]');
        const clickEvent = this.getScopedClickEvent();
        this.openModalBtn.off(clickEvent);
        this.openModalBtn.on(clickEvent, () => this.onOpenModalBtnClick());
        this.refreshFormBindings();
    }

    public refreshFormBindings(): void {
        if (this.modal === null) {
            this.tearOnfidoDown();
            this.form = $([]);
            this.formOnfidoMount = $([]);
            this.formBackBtn = $([]);
            this.formSubmitBtn = $([]);
            this.formOnfidoDocumentFrontId = $([]);
            this.formOnfidoDocumentBackId = $([]);
            this.formButtonsContainer = $([]);
            this.formBankIdAuthenticateBtn = $([]);
            this.loadingAnimation = $([]);
            this.formErrorContainer = $([]);
            this.formErrorText = $([]);
            return;
        }
        const modal = this.modal.$element;
        this.form = modal.findWithSelf('[data-identity-check-process-form]');
        this.formBackBtn = modal.find('[data-identity-check-process-form-back-btn]');
        this.formSubmitBtn = modal.find('[data-identity-check-process-form-submit-btn]');
        this.formOnfidoMount = modal.find('[data-identity-check-process-form-onfido-mount]');
        this.formOnfidoDocumentFrontId = modal.find('[data-identity-check-process-form-onfido-document-front-id]');
        this.formOnfidoDocumentBackId = modal.find('[data-identity-check-process-form-onfido-document-back-id]');
        this.formButtonsContainer = modal.find('[data-identity-check-process-form-buttons-container]');
        this.formBankIdAuthenticateBtn = modal.find('[data-identity-check-process-form-bank-id-authenticate]');
        this.loadingAnimation = modal.find('[data-identity-check-loading-animation]');
        this.formErrorContainer = modal.find('[data-identity-check-process-form-error-container]');
        this.formErrorText = modal.find('[data-identity-check-process-form-error-text]');
        this.mountOnfido();

        if (this.form) {
            const shortModalLabels = ['bank_id', 'identification_provider'];
            modal?.toggleClass('modal--short', shortModalLabels.includes(this.getCurrentStepLabel()));
            if (this.getCurrentStepLabel() === 'identification_provider') {
                FormHelp.getAll(modal.findWithSelf('.form__input-help'));
            }
         }
    }

    private onOpenModalBtnClick(): void {
        if (this.isVerified) {
            return;
        }
        if (this.modal === null) {
            const modal = $(this.config.modalTemplate);
            $('body').append(modal);
            this.modal = Modal.getOne(modal[0]);
            componentsLoader.load(modal);
            AsyncContent.getOne(modal.findWithSelf('[data-async-content]'))?.addEventListener(AsyncContentEvents.Loaded, () => {
                this.form = modal.findWithSelf('[data-identity-check-process-form]');
                const asyncForm = AsyncForm.getOne(this.form) as AsyncForm | null;
                asyncForm?.setConfig({
                    loadingAnimationHandler: (event) => {
                        if (this.loadingAnimation.length === 0) {
                            this.loadingAnimation = modal.find('[data-identity-check-loading-animation]');
                        }
                        event === 'show' ? this.showLoadingAnimationInModal() : this.hideLoadingAnimationInModal();
                    },
                });
                asyncForm?.addEventListener(AsyncFormEvents.FormReplaced, () => this.onFormContentChange());
                asyncForm?.addEventListener(AsyncFormEvents.ContentReplaced, () => this.onFormContentChange());
                asyncForm?.addEventListener(AsyncFormEvents.PreSubmit, () => {
                    this.tearOnfidoDown();
                    this.stopOnfidoCheckStatusMonitor();
                    this.stopBankIdCheckStatusMonitor();
                });
                asyncForm?.addEventListener(AsyncFormEvents.PreRefresh, () => {
                    this.tearOnfidoDown();
                    this.stopOnfidoCheckStatusMonitor();
                    this.stopBankIdCheckStatusMonitor();
                });
                this.onFormContentChange();
            });
        }
        this.modal?.show();
    }

    private showLoadingAnimationInModal(): void {
        this.form.hide();
        this.loadingAnimation.show();
    }

    private hideLoadingAnimationInModal(): void {
        this.form.show();
        this.loadingAnimation.hide();
    }

    private onFormContentChange(): void {
        if (this.modal === null) {
            return;
        }
        this.refreshFormBindings();
        this.startOnfidoCheckStatusMonitor();
        this.handleBankIdAuthentication();
        this.startBankIdCheckStatusMonitor();
    }

    private handleBankIdAuthentication(): void {
        const url = this.form.attr('data-identity-check-process-form-bank-id-oauth-url');
        if (this.formBankIdAuthenticateBtn.length === 0 || typeof url !== 'string') {
            return;
        }
        this.formSubmitBtn.hide();
        const clickEvent = this.getScopedClickEvent();
        this.formBankIdAuthenticateBtn.off(clickEvent);
        this.formBankIdAuthenticateBtn.on(clickEvent, () => {
            window.open(url, '_blank');
            this.form.trigger('submit');
        });
    }

    public getCurrentStepNumber(): number {
        return parseInt(this.form.attr('data-identity-check-process-form-step') as string);
    }

    public getCurrentStepLabel(): string {
        return this.form.attr('data-identity-check-process-form-step-label') as string;
    }

    private startBankIdCheckStatusMonitor(): void {
        this.stopBankIdCheckStatusMonitor();
        const url = this.form.attr('data-identity-check-process-form-bank-id-check-url');
        if (typeof url !== 'string') {
            return;
        }
        this.formSubmitBtn.hide();
        this.hideFormError();
        this.bankIdCheckStatusMonitorInterval = setInterval(async () => {
            try {
                const response = await axios.request<null, AxiosResponse<BankIdCheck>>({
                    method: 'POST',
                    url: url,
                    data: this.form.serialize(),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                });
                const isCleared = response.data.isCleared;
                if (isCleared === null) {
                    return;
                }
                if (this.getCurrentStepLabel() !== FormStep.BankIdCheck) {
                    return;
                }
                this.stopBankIdCheckStatusMonitor();
                if (!isCleared) {
                    this.formButtonsContainer.show();
                    this.formBackBtn.show();
                    this.formSubmitBtn.hide();
                    if (typeof response.data.errorMessage === 'string') {
                        this.showFormError(response.data.errorMessage);
                    }
                    return;
                }
                this.form.trigger('submit');
            } catch (e) {
                logger.log(e);
            }
        }, 5000);
    }

    private stopBankIdCheckStatusMonitor(): void {
        if (this.bankIdCheckStatusMonitorInterval !== null) {
            clearInterval(this.bankIdCheckStatusMonitorInterval);
            this.bankIdCheckStatusMonitorInterval = null;
        }
    }

    private startOnfidoCheckStatusMonitor(): void {
        this.stopOnfidoCheckStatusMonitor();
        const url = this.form.attr('data-identity-check-process-form-onfido-check-url');
        if (typeof url !== 'string') {
            return;
        }
        this.hideFormError();
        this.formButtonsContainer.hide();
        this.onfidoCheckStatusMonitorInterval = setInterval(async () => {
            try {
                const response = await axios.request<null, AxiosResponse<OnfidoCheck>>({
                    method: 'POST',
                    url: url,
                    data: this.form.serialize(),
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                });
                const isCleared = response.data.isCleared;
                if (isCleared === null) {
                    return;
                }
                const stepLabel = this.getCurrentStepLabel();
                if (
                    stepLabel !== FormStep.FirstOnfidoDocumentCheck
                    && stepLabel !== FormStep.SecondOnfidoDocumentCheck
                    && stepLabel !== FormStep.AmlOnfidoCheck
                ) {
                    return;
                }
                this.stopOnfidoCheckStatusMonitor();
                if (!isCleared) {
                    this.formButtonsContainer.show();
                    this.formBackBtn.show();
                    this.formSubmitBtn.hide();
                    if (typeof response.data.errorMessage === 'string') {
                        this.showFormError(response.data.errorMessage);
                    }
                    return;
                }
                this.form.trigger('submit');
            } catch (error) {
                logger.log(error);
            }
        }, 5000);
    }

    private showFormError(message: string): void {
        this.formErrorContainer.show();
        this.formErrorText.html(message);
    }

    private hideFormError(): void {
        this.formErrorContainer.hide();
        this.formErrorText.empty();
    }

    private stopOnfidoCheckStatusMonitor(): void {
        if (this.onfidoCheckStatusMonitorInterval !== null) {
            clearInterval(this.onfidoCheckStatusMonitorInterval);
            this.onfidoCheckStatusMonitorInterval = null;
        }
    }

    private closeModalAndResetWidget(): void {
        this.stopVerifiedStatusMonitor();
        this.stopOnfidoCheckStatusMonitor();
        this.stopBankIdCheckStatusMonitor();
        this.tearOnfidoDown();
        this.modal?.hideAndRemove();
        this.modal = null;
    }

    private mountOnfido(): void {
        if (this.formOnfidoMount.length === 0 || this.formOnfido !== null) {
            return;
        }
        const clearedDocumentTypes = JSON.parse(this.form.attr('data-identity-check-process-form-cleared-document-types') as string) as string[];
        const documentTypes = {} as { [_key: string]: boolean };
        for (const documentType of ['passport', 'driving_licence', 'national_identity_card', 'residence_permit']) {
            if (clearedDocumentTypes.indexOf(documentType) < 0) {
                documentTypes[documentType] = true;
            }
        }
        const steps: Array<OnfidoPublicStepTypes | OnfidoPublicStepConfig> = [];
        const stepLabel = this.getCurrentStepLabel();
        if (stepLabel === FormStep.FirstOnfidoDocumentUpload) {
            steps.push('welcome');
            steps.push({
                type: 'face',
                options: {
                    requestedVariant: 'video',
                },
            });
        }
        steps.push({
            type: 'document',
            options: {
                documentTypes: documentTypes,
            },
        });
        this.formSubmitBtn.hide();
        this.formOnfido = Onfido.init({
            token: this.form.attr('data-identity-check-process-form-onfido-sdk-token'),
            containerEl: this.formOnfidoMount[0],
            language: this.getOnfidoLanguageConfig(),
            customUI: this.getOnfidoUIConfig(),
            onComplete: (data) => {
                if (typeof data['document_front'] !== 'undefined') {
                    this.formOnfidoDocumentFrontId.val(data['document_front']?.id);
                }
                if (typeof data['document_back'] !== 'undefined') {
                    this.formOnfidoDocumentBackId.val(data['document_back']?.id);
                }
                this.formButtonsContainer.hide();
                setTimeout(() => this.form.trigger('submit'), 3000);
            },
            onError: (error) => {
                logger.log(error);
            },
            steps: steps,
        });
    }

    private tearOnfidoDown(): void {
        if (this.formOnfido !== null) {
            this.formOnfido.tearDown();
            this.formOnfidoMount.empty();
            this.formOnfido = null;
        }
    }

    private startVerifiedStatusMonitor(): void {
        this.stopVerifiedStatusMonitor();
        this.verifiedStatusMonitorInterval = setInterval(async () => {
            try {
                const isVerified = await this.isVerifiedFromServer();
                if (isVerified) {
                    this.stopVerifiedStatusMonitor();
                    this.isVerified = true;
                }
            } catch (e) {
                logger.log(e);
            }
        }, 5000);
    }

    private stopVerifiedStatusMonitor(): void {
        if (this.verifiedStatusMonitorInterval !== null) {
            clearInterval(this.verifiedStatusMonitorInterval);
            this.verifiedStatusMonitorInterval = null;
        }
    }

    public async isVerifiedFromServer(): Promise<boolean> {
        const response = await axios.request<null, AxiosResponse<IdCheck>>({
            method: 'GET',
            url: this.config.detailUrlTemplate.replace('__id__', this.getId()),
        });
        return response.data.isVerified;
    }

    public getId(): string {
        let id = this.idInput.val() as string;
        if (String.isNullOrWhiteSpace(id)) {
            this.idInput.val(this.config.prototypeId);
            id = this.config.prototypeId;
        }
        return id;
    }

    private getOnfidoLanguageConfig(): OnfidoSupportedLanguages | OnfidoLocaleConfig {
        if (APP_CONFIG.common.locale === 'cs') {
            const language = Object.assign({}, onfidoLanguageCs);
            const stepLabel = this.getCurrentStepLabel();
            if (stepLabel === FormStep.FirstOnfidoDocumentUpload) {
                language.cross_device_checklist.title = 'Děkujeme';
                language.cross_device_checklist.subtitle = 'Nyní budeme ověřovat Vaši totožnost z prvního průkazu totožnosti a následně přistoupíme k ověření Vaší osoby přes Váš druhý průkaz totožnosti.';
            } else if (stepLabel === FormStep.SecondOnfidoDocumentUpload) {
                language.doc_select.title = 'Potvrďte svoji totožnost z druhého dokladu totožnosti';
                language.doc_select.subtitle = 'Vyberte svůj druhý doklad totožnosti, musí se jednat o oficiální průkaz totožnosti s fotografií.';
                language.cross_device_checklist.title = 'Děkujeme';
                language.cross_device_checklist.subtitle = 'Nyní budeme ověřovat Vaši totožnost z druhého dokladu totožnosti.';
            }
            return {
                phrases: language
            };
        } else if (APP_CONFIG.common.locale === 'en') {
            const language = Object.assign({}, onfidoLanguageEn);
            const stepLabel = this.getCurrentStepLabel();
            if (stepLabel === FormStep.FirstOnfidoDocumentUpload) {
                language.cross_device_checklist.title = 'Thank you';
                language.cross_device_checklist.subtitle = 'We will now verify your identity from the first identity document and then proceed to verify your identity through your second identity document.';
            } else if (stepLabel === FormStep.SecondOnfidoDocumentUpload) {
                language.doc_select.title = 'Confirm your identity from the second identity document';
                language.doc_select.subtitle = 'Select your second identity document, it must be an official identity document with a photograph.';
                language.cross_device_checklist.title = 'Thank you';
                language.cross_device_checklist.subtitle = 'We will now verify your identity from the second identity document.';
            }
            return {
                phrases: language
            };
        }
        return 'en_US';
    }

    private getOnfidoUIConfig(): OnfidoUICustomizationOptions {
        return {
            colorBackgroundButtonPrimary: Colors.PrimaryColor,
            colorBackgroundButtonPrimaryHover: Colors.PrimaryColorHover,
            colorBackgroundButtonPrimaryActive: Colors.PrimaryColorHover,
            colorContentButtonPrimaryText: Colors.White,
            colorBorderButtonPrimary: Colors.PrimaryColor,
            colorBackgroundIcon: Colors.PrimaryColor,
            colorBackgroundButtonSecondary: Colors.MainFontColor,
            colorBackgroundButtonSecondaryHover: Colors.MainFontColorHover,
            colorBackgroundButtonSecondaryActive: Colors.MainFontColorHover,
            colorContentButtonSecondaryText: Colors.White,
            colorBorderButtonSecondary: Colors.MainFontColor,
            colorBorderDocTypeButton: Colors.PrimaryColor,
            colorBorderDocTypeButtonHover: Colors.PrimaryColorHover,
            colorBorderDocTypeButtonActive: Colors.PrimaryColorHover,
            colorBorderLinkUnderline: Colors.White,
            colorContentLinkTextHover: Colors.PrimaryColorHover,
            colorBackgroundLinkHover: Colors.White,
            colorBackgroundLinkActive: Colors.White,
            colorBackgroundAlertInfo: Colors.PrimaryColor,
            colorBackgroundInfoPill: Colors.PrimaryColor,
        };
    }
}

enum FormStep
{
    IdentificationTarget = 'identification_target',
    IdentificationProvider = 'identification_provider',
    ClientData = 'client_data',
    FirstOnfidoDocumentUpload = 'first_onfido_document_upload',
    FirstOnfidoDocumentCheck = 'first_onfido_document_check',
    SecondOnfidoDocumentUpload = 'second_onfido_document_upload',
    SecondOnfidoDocumentCheck = 'second_onfido_document_check',
    AmlOnfidoCheck = 'aml_onfido_check',
    BankId = 'bank_id',
    BankIdCheck = 'bank_id_check',
    Completed = 'completed',
}

export enum IdentityCheckEvents
{
    VERIFIED = 'app.identity-check.verified',
}

type IdCheck = {
    id: string;
    isVerified: boolean;
}

type OnfidoCheck = {
    isCleared: boolean | null;
    errorMessage?: string;
}

type BankIdCheck = {
    isCleared: boolean | null;
    errorMessage?: string;
}

type Config = {
    modalTemplate: string;
    verifiedButtonContent: string;
    notVerifiedButtonContent: string;
    isVerified: boolean;
    prototypeId: string;
    detailUrlTemplate: string;
    loadingAnimationInModalTemplate: string;
};
