import Component from "app/components/component";

export default class FormHelp extends Component
{
    public getComponentName(): string {
        return 'FormHelp';
    }

    protected initialize() {
        this.$element.findWithSelf('[data-form-help-button]').on('click', () => {
            this.$element.findWithSelf('[data-form-help-text]').toggleClass('d-none');
        })
    }
}
