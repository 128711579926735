import Component from 'app/components/component';

export default class MobileNavigation extends Component
{
    public getComponentName(): string {
        return 'MobileNavigation';
    }

    protected initialize() {
        this.$element.find('[data-mobile-nav-toggler]').on('click', () => {
            this.$element.findWithSelf('[data-mobile-nav-items]').toggleClass('d-none');
        })
    }
}
