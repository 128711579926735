import Component from 'app/components/component';
import Loader from 'app/utils/loader';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {handleAxiosError, handleAxiosErrorSilently} from 'app/utils/axios';
import Random from 'app/utils/random';
import {messenger} from 'app/utils/messenger';
import {componentsLoader} from 'app/service/components-loader';

export default class AsyncForm extends Component<Config, HTMLFormElement>
{
    private isLoading: boolean = false;
    private refreshProcessId: number | null = null;
    private refreshTimeout: NodeJS.Timeout | null = null;
    private _lastFocusedInput: JQuery | null = null;
    private _focusedInput: JQuery | null = null;
    private refreshFormOnFocusOut: boolean = false;

    private static readonly defaultTransportMethod: 'post' = 'post';

    protected initialize(): void {
        this.refreshBindings();
    }

    public refreshBindings() {
        if (this.config.refreshFormExclusively !== true) {
            this.$element
                .off(this.getScopedSubmitEvent())
                .on(this.getScopedSubmitEvent(), (event) => this.onSubmit(event as JQuery.SubmitEvent));
        }
        const inputs = this.$element.findWithSelf(':input')
            .off(this.getScopedFocusEvent())
            .off(this.getScopedFocusOutEvent())
            .on(this.getScopedFocusEvent(), (event) => this.onFocusIn(event as JQuery.FocusEvent))
            .on(this.getScopedFocusOutEvent(), (event) => this.onFocusOut(event as JQuery.FocusOutEvent));
        if (this.config.refreshFormOnEveryChange === true) {
            inputs
                .off(this.getScopedChangeEvent())
                .on(this.getScopedChangeEvent(), () => this.refresh());
        } else if (typeof this.config.refreshFormSelector === 'string') {
            this.$element.findWithSelf(this.config.refreshFormSelector).each((_, element) => {
                const $element = $(element);
                if ($element.prop('tagName') === 'BUTTON' || $element.attr('type') === 'submit') {
                    $element.off(this.getScopedClickEvent());
                    $element.on(this.getScopedClickEvent(), (event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.refresh(event);
                    });
                    return;
                }
                $element.off(this.getScopedChangeEvent());
                $element.on(this.getScopedChangeEvent(), () => this.refresh());
            });
        }
    }

    public getComponentName(): string {
        return 'AsyncForm';
    }

    private toggleLoading(): void {
        this.isLoading = !this.isLoading;
        if (this.isLoading) {
            this.showLoadingAnimation();
            return;
        }
        this.hideLoadingAnimation();
    }

    private showLoadingAnimation(): void {
        const handler = this.config.loadingAnimationHandler;
        if (typeof handler === 'function') {
            handler('show', this);
        } else {
            Loader.showOverElement(this.$element);
        }
    }

    private hideLoadingAnimation(): void {
        const handler = this.config.loadingAnimationHandler;
        if (typeof handler === 'function') {
            handler('hide', this);
        } else {
            Loader.hideOverElement(this.$element);
        }
    }

    public refresh(event: JQuery.Event | null = null): void {
        if (this.refreshTimeout !== null) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
        }
        if (this.isLoading) {
            return;
        }
        let url = this.config.refreshFormUrl;
        if (typeof url !== 'string') {
            const urlObj = new URL(this.getActionUrl());
            urlObj.searchParams.append('ajax', 'refresh-form');
            url = urlObj.toString();
        }
        const processId = Random.integer(0, 1000000);
        this.refreshProcessId = processId;
        if (this.config.showLoadingAnimationDuringRefresh === true) {
            this.showLoadingAnimation();
        }
        this.refreshTimeout = setTimeout(async () => {
            try {
                this.triggerEvent(AsyncFormEvents.PreRefresh, event);
                const response = await axios.request<any, AxiosResponse<RefreshResponse>>({
                    url: url,
                    method: typeof this.config.refreshTransportMethod === 'string'
                        ? this.config.refreshTransportMethod
                        : this.getTransportMethod(),
                    data: this.serializeForm(event),
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });
                if (this.refreshProcessId !== processId) {
                    return;
                }
                if (this.config.showLoadingAnimationDuringRefresh === true) {
                    this.hideLoadingAnimation();
                }
                this.refreshProcessId = null;
                this.refreshTimeout = null;
                if (typeof response.data.form === 'string') {
                    this.replaceFormContent(this.$element, $(response.data.form));
                }
                this.triggerEvent(AsyncFormEvents.RefreshSuccess, event, {response: response.data});
            } catch (error) {
                if (this.refreshProcessId !== processId) {
                    return;
                }
                if (this.config.showLoadingAnimationDuringRefresh === true) {
                    this.hideLoadingAnimation();
                }
                const axiosError = error as AxiosError<RefreshResponse>;
                this.refreshProcessId = null;
                this.refreshTimeout = null;
                this.triggerEvent(AsyncFormEvents.RefreshError, event, {response: axiosError.response?.data});
                handleAxiosError(axiosError);
            }
        }, 500);
    }

    private onFocusIn(event: JQuery.FocusEvent): void {
        this._focusedInput = $(event.target);
        this._lastFocusedInput = $(event.target);
        if (this.refreshTimeout !== null) {
            clearTimeout(this.refreshTimeout);
            this.refreshTimeout = null;
            this.refreshProcessId = null;
            this.refreshFormOnFocusOut = true;
        }
    }

    private onFocusOut(_: JQuery.FocusOutEvent): void {
        this._focusedInput = null;
        if (this.refreshFormOnFocusOut) {
            this.refreshFormOnFocusOut = false;
            this.refresh();
        }
    }

    private async onSubmit(event: JQuery.SubmitEvent): Promise<void> {
        event.preventDefault();
        if (this.isLoading) {
            return;
        }
        if (this.refreshProcessId === null) {
            await this.submit(event);
            return;
        }
        let pendingForRefreshFinisInterval = setInterval(async () => {
            if (this.refreshProcessId === null) {
                clearInterval(pendingForRefreshFinisInterval);
                await this.submit(event);
            }
        }, 500);
    }

    private replaceFormContent(oldForm: JQuery, newForm: JQuery): void {
        oldForm.html(newForm.html());
        const newFormAttributes = newForm.prop('attributes') as NamedNodeMap;
        const attributesMap = {} as { [_key: string]: boolean };
        for (let i = 0; i < newFormAttributes.length; i++) {
            const attribute = newFormAttributes[i];
            oldForm.attr(attribute.name, attribute.value);
            attributesMap[attribute.name] = true;
        }
        const oldFormAttributes = oldForm.prop('attributes') as NamedNodeMap;
        for (let i = 0; i < oldFormAttributes.length; i++) {
            const oldAttribute = oldFormAttributes[i];
            if (!attributesMap[oldAttribute.name]) {
                oldForm.removeAttr(oldAttribute.name);
            }
        }
        componentsLoader.load(oldForm);
        this.refreshBindings();
        this.triggerEvent(AsyncFormEvents.FormReplaced, null, {oldForm: oldForm, newForm: newForm});
    }

    private replaceElements(oldElement: JQuery, newElement: JQuery): void {
        oldElement.replaceWith(newElement);
        componentsLoader.load(oldElement);
        this.triggerEvent(AsyncFormEvents.ContentReplaced, null, {oldElement: oldElement, newElement: newElement});
    }

    public submit(event: JQuery.Event | null = null): Promise<SubmitResponse> {
        return new Promise<SubmitResponse>(async (resolve, reject) => {
            try {
                this.triggerEvent(AsyncFormEvents.PreSubmit, event);
                this.toggleLoading();
                const response = await axios.request<any, AxiosResponse<SubmitResponse>>({
                    url: this.getActionUrl(),
                    method: this.getTransportMethod(),
                    data: this.serializeForm(event),
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                });
                this.toggleLoading();
                if (typeof response.data.message === 'string') {
                    messenger.success(response.data.message);
                } else if (typeof this.config.successMessage === 'string') {
                    messenger.success(this.config.successMessage);
                }
                if (typeof response.data.url === 'string') {
                    this.triggerEvent(AsyncFormEvents.SubmitSuccess, event, {response: response.data});
                    location.href = response.data.url;
                    if (response.data.forceReload === true || response.data.reloadPage === true) {
                        setTimeout(() => location.reload(), 500);
                    }
                    resolve(response.data);
                    return;
                }
                if (this.config.reloadPageOnSuccess === true || response.data.reloadPage === true) {
                    this.triggerEvent(AsyncFormEvents.SubmitSuccess, event, {response: response.data});
                    location.reload();
                    resolve(response.data);
                    return;
                }
                if (typeof response.data.form === 'string') {
                    this.replaceFormContent(this.$element, $(response.data.form));
                } else if (typeof response.data.content === 'string') {
                    this.replaceElements(this.$element, $(response.data.content));
                }
                this.triggerEvent(AsyncFormEvents.SubmitSuccess, event, {response: response.data});
                resolve(response.data);
            } catch (error) {
                const axiosError = error as AxiosError<SubmitResponse>;
                this.toggleLoading();
                this.triggerEvent(AsyncFormEvents.SubmitError, event, {error: axiosError});
                const response = axiosError.response;
                if (response === undefined) {
                    handleAxiosError(axiosError);
                    reject(axiosError);
                    return;
                }
                let showCommonError = true;
                if (typeof response.data.message === 'string') {
                    messenger.error(response.data.message);
                    showCommonError = false;
                } else if (typeof this.config.errorMessage === 'string') {
                    messenger.error(this.config.errorMessage);
                    showCommonError = false;
                }
                if (typeof response.data.url === 'string') {
                    location.href = response.data.url;
                    if (response.data.forceReload === true || response.data.reloadPage === true) {
                        setTimeout(() => location.reload(), 500);
                    }
                    handleAxiosErrorSilently(axiosError);
                    reject(axiosError);
                    return;
                }
                if (this.config.reloadPageOnError === true || response.data.reloadPage === true) {
                    location.reload();
                    handleAxiosErrorSilently(axiosError);
                    reject(axiosError);
                    return;
                }
                if (typeof response.data.form === 'string') {
                    this.replaceFormContent(this.$element, $(response.data.form));
                    showCommonError = false;
                } else if (typeof response.data.content === 'string') {
                    this.replaceElements(this.$element, $(response.data.content));
                    showCommonError = false;
                }
                if (showCommonError) {
                    handleAxiosError(axiosError);
                } else {
                    handleAxiosErrorSilently(axiosError);
                }
                reject(axiosError);
            }
        });
    }

    private serializeForm(event: JQuery.Event | null = null): FormData {
        const data = this.$element.serializeArray();
        if (event !== null && typeof (event as any).originalEvent !== 'undefined' && (event as any).originalEvent instanceof SubmitEvent) {
            const submitter = $((event as any).originalEvent.submitter as HTMLElement);
            if (submitter.attr('name') !== undefined && submitter.attr('type') === 'submit') {
                data.push({name: submitter.attr('name'), value: submitter.val()} as JQuery.NameValuePair);
            }
        } else if (event !== null && typeof (event as any).target !== 'undefined' && (event as any).target instanceof HTMLElement) {
            const submitter = $((event as any).target as HTMLElement);
            if (submitter.attr('name') !== undefined && submitter.attr('type') === 'submit') {
                data.push({name: submitter.attr('name'), value: submitter.val()} as JQuery.NameValuePair);
            }
        }
        const output = new FormData();
        for (let value of data) {
            output.append(value.name, value.value);
        }
        return output;
    }

    public getActionUrl(): string {
        if (typeof this.config.actionUrl === 'string') {
            return this.config.actionUrl;
        }
        if (typeof this.config.actionUrl === 'function') {
            return this.config.actionUrl(this);
        }
        if (typeof this.$element.attr('action') === 'string') {
            return this.$element.attr('action') as string;
        }
        return location.href;
    }

    public getTransportMethod(): 'get' | 'post' {
        if (typeof this.config.transportMethod === 'string') {
            return this.config.transportMethod;
        }
        if (typeof this.$element.attr('method') === 'string') {
            this.$element.attr('method');
        }
        return AsyncForm.defaultTransportMethod;
    }

    get focusedInput(): JQuery | null {
        return this._focusedInput;
    }

    get lastFocusedInput(): JQuery | null {
        return this._lastFocusedInput;
    }
};

export enum AsyncFormEvents
{
    PreSubmit = 'app.async-form.pre-submit',
    SubmitSuccess = 'app.async-form.submit-success',
    SubmitError = 'app.async-form.submit-error',
    FormReplaced = 'app.async-form.form-replaced',
    ContentReplaced = 'app.async-form.content-replaced',
    PreRefresh = 'app.async-form.pre-refresh',
    RefreshSuccess = 'app.async-form.refresh-success',
    RefreshError = 'app.async-form.refresh-error',
}

type SubmitResponse = {
    message?: string,
    url?: string,
    reloadPage?: boolean,
    form?: string,
    content?: string,
    forceReload?: boolean,
};

type RefreshResponse = {
    form?: string
}

type LoadingAnimationHandlerEvent = 'show' | 'hide';

export type Config = {
    actionUrl?: string | ((component: AsyncForm) => string),
    transportMethod?: 'get' | 'post',
    reloadPageOnSuccess?: boolean,
    reloadPageOnError?: boolean,
    successMessage?: string | null,
    errorMessage?: string | null,
    refreshFormSelector?: string,
    refreshFormOnEveryChange?: boolean,
    refreshFormUrl?: string,
    refreshTransportMethod?: 'get' | 'post',
    refreshFormExclusively?: boolean;
    showLoadingAnimationDuringRefresh?: boolean,
    loadingAnimationHandler?: null | ((event: LoadingAnimationHandlerEvent, component: AsyncForm) => void);
};
