import Component from 'app/components/component';
import Files from 'app/utils/files';
import {logger} from 'app/service/logger';
import InvalidStateException from 'app/errors/invalid-state-exception';
import axios, {AxiosResponse} from 'axios';
import {handleAxiosError} from 'app/utils/axios';
import elementAttrChangeObserver from 'app/service/element-attr-change-observer';
import DropEvent = JQuery.DropEvent;

export default class FileUpload extends Component <Config, HTMLDivElement>
{
    private readonly select: JQuery;
    private readonly dragAndDropContainer: JQuery;
    private readonly uploadedFilesContainer: JQuery;
    private dummyFileUpload: HTMLInputElement | null = null;
    private files: FileUploadItem[] = [];

    public static readonly defaultConfig: Partial<Config> = {
        multiple: false,
        maxFiles: null,
        maxFileSize: null,
        extensions: [],
    };

    constructor(element: HTMLDivElement, config: Config) {
        super(element, config, false);
        this.select = this.$element.findWithSelf('[data-file-upload-select]');
        this.dragAndDropContainer = this.$element.findWithSelf('[data-file-upload-drag-and-drop-container]');
        this.uploadedFilesContainer = this.$element.findWithSelf('[data-file-upload-uploaded-files-container]');
        this.initialize();
    }

    protected initialize() {
        this.select.css('display', 'none');
        this.dragAndDropContainer
            .on('drag dragstart dragend dragover dragenter dragleave drop', (event: Event) => {
                event.preventDefault();
                event.stopPropagation();
            })
            .on('dragover dragenter', () => {
                this.dragAndDropContainer.addClass('file-upload__drag-and-drop-container--is-dragover');
            })
            .on('dragleave dragend drop', () => {
                this.dragAndDropContainer.removeClass('file-upload__drag-and-drop-container--is-dragover');
            })
            .on('drop', (event: DropEvent) => this.onDrop(event))
            .on('click', () => this.onClickOnDragAndDrop());
        this.select.find('option').each((_, element): void => {
            const option = $(element);
            const metadata = option.data('storageFile');
            if (typeof metadata === 'object') {
                const item = new FileUploadItem(this);
                item.metadata = metadata;
                item.onRemove = () => this.removeFile(item);
                item.show();
                !this.isDisabledOrReadonly() ? this.addFile(item) : this.displayFile(item);
            }
        });
        elementAttrChangeObserver.observe(this.select[0], ['readonly', 'disabled'], () => {
            if (this.isDisabledOrReadonly()) {
                this.disable();
                return;
            }
            this.enable();
        });
        if (this.isDisabledOrReadonly()) {
            this.disable();
        }
    }

    public getComponentName(): string {
        return 'FileUpload';
    }

    private getDummyFileInput(): HTMLInputElement {
        if (this.dummyFileUpload === null) {
            this.dummyFileUpload = document.createElement('input');
            this.dummyFileUpload.style.display = 'none';
            this.dummyFileUpload.type = 'file';
            this.dummyFileUpload.multiple = this.config.multiple;
            if (this.config.extensions.length > 0) {
                this.dummyFileUpload.accept = this.config.extensions.join(',');
            }
            this.dummyFileUpload.addEventListener('change', () => this.onDummyFileInputChange());
            document.body.append(this.dummyFileUpload);
        }
        return this.dummyFileUpload;
    }

    private onClickOnDragAndDrop(): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        this.getDummyFileInput().click();
    }

    private onDrop(event: DropEvent): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        const files = event.originalEvent?.dataTransfer?.files;
        if (typeof files !== 'undefined') {
            this.processFileList(files);
        }
    }

    private onDummyFileInputChange(): void {
        const input = this.getDummyFileInput();
        if (input.files !== null) {
            this.processFileList(input.files);
        }
        input.value = '';
    }

    private processFileList(files: FileList): void {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            this.upload(file);
        }
    }

    public upload(file: File): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        Files.fileToBase64(file)
            .then((base64) => {
                if (
                    (!this.config.multiple && this.files.length > 0)
                    || (
                        this.config.maxFiles !== null
                        && this.config.maxFiles > 0
                        && this.files.length > this.config.maxFiles
                    )
                ) {
                    this.removeFile(this.files.at(0) as FileUploadItem);
                }
                const item = new FileUploadItem(this);
                item.base64Content = base64;
                item.filename = file.name;
                item.onUpload = () => this.setSelectedValue();
                item.onRemove = () => this.removeFile(item);
                this.addFile(item);
                item.upload();
            })
            .catch((e) => logger.log(e));
    }

    public empty(): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        this.files = [];
        this.uploadedFilesContainer.empty();
        this.setSelectedValue();
    }

    private addFile(file: FileUploadItem): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        this.files.push(file);
        this.displayFile(file);
        this.setSelectedValue();
    }

    private displayFile(file: FileUploadItem): void {
        this.uploadedFilesContainer.append(file.element);
    }

    private removeFile(file: FileUploadItem): void {
        if (this.isDisabledOrReadonly()) {
            return;
        }
        this.files.remove(file);
        file.element.remove();
        this.setSelectedValue();
    }

    private setSelectedValue(): void {
        this.select.empty();
        const value: string[] = [];
        this.files.forEach((file) => {
            if (file.isUploaded()) {
                const id = file.metadata!.id;
                value.push(id);
                this.select.append($('<option>')
                    .attr('value', id)
                    .text(id)
                    .attr('selected', 'selected'));
            }
        });
        if (value.length === 0) {
            this.select.val(this.config.multiple ? [] : '');
            return;
        }
        this.select.val(this.config.multiple ? value : value[0]);
    }

    public isReadOnly(): boolean {
        return this.select.attr('readonly') !== undefined;
    }

    public isDisabled(): boolean {
        return this.select.attr('disabled') !== undefined;
    }

    public disable(): void {
        if (this.select.attr('disabled') === undefined) {
            this.select.prop('disabled', true);
        }
        this.dragAndDropContainer.addClass('file-upload__drag-and-drop-container--is-disabled');
        this.uploadedFilesContainer.find('[data-file-upload-item-remove-file]').attr('disabled', 'disabled');
    }

    public enable(): void {
        if (this.select.attr('disabled') !== undefined) {
            this.select.prop('disabled', false);
        }
        this.dragAndDropContainer.removeClass('file-upload__drag-and-drop-container--is-disabled');
        this.uploadedFilesContainer.find('[data-file-upload-item-remove-file]').removeAttr('disabled');
    }
};

type Config = {
    multiple: boolean;
    uploadUrl: string;
    maxFiles: number | null;
    maxFileSize: number | null; // in bytes
    extensions: string[];
    itemTemplate: string;
    itemUploadingTemplate: string;
};

type FileMetadata = {
    id: string;
    filename: string;
    clientFilename: string;
    url: string;
    downloadUrl: string;
    detailUrl: string;
    mimeType: string;
    extension: number;
    size: string;
};

type FileUploadRequest = {
    content: string;
    filename: string;
}

class FileUploadItem
{
    private readonly fileUpload: FileUpload;
    private _base64Content: string | null = null;
    private _filename: string | null = null;
    private _onUpload: null | ((file: FileUploadItem) => void) = null;
    private _onRemove: null | ((file: FileUploadItem) => void) = null;
    private _metadata: null | FileMetadata = null;
    private _element: JQuery;

    constructor(fileUpload: FileUpload) {
        this.fileUpload = fileUpload;
        this._element = $('<div>');
    }

    public isUploaded(): boolean {
        return this._metadata !== null;
    }

    public upload(): void {
        if (this.base64Content === null) {
            throw new InvalidStateException('Base 64 file content is NULL.');
        }
        if (this.isUploaded()) {
            throw new InvalidStateException('File item is already uploaded.');
        }
        const template = $(this.fileUpload.config.itemUploadingTemplate);
        this._element.replaceWith(template);
        this._element = template;
        const progressBarValue = this._element.findWithSelf('[data-file-upload-item-uploading-progress-bar-value]');
        axios
            .request<FileUploadRequest, AxiosResponse<FileMetadata>>({
                url: this.fileUpload.config.uploadUrl,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {
                    content: this.base64Content,
                    filename: this.filename,
                },
                onUploadProgress: (e) => {
                    const percentage = (e.loaded / (e.total as number)) * 100;
                    const value = (
                        Math.floor(percentage * 100) / 100
                    ).toString();
                    progressBarValue.css('width', value + '%');
                    progressBarValue.text(value + ' %');
                },
            })
            .then((e) => {
                this.metadata = e.data;
                if (this.onUpload !== null) {
                    this.onUpload(this);
                }
                this._element?.
                    closest('[data-required-file-upload-wrapper]')?.
                    find('.form__errors')?.
                    addClass('d-none');
                this.show();
            })
            .catch((e) => {
                handleAxiosError(e);
                this.remove();
            });
    }

    private assertIsUploaded(): void {
        if (!this.isUploaded()) {
            throw new InvalidStateException('File item is not uploaded.');
        }
    }

    public show(): void {
        this.assertIsUploaded();
        const template = $(this.fileUpload.config.itemTemplate);
        this._element.replaceWith(template);
        this._element = template;
        this._element.findWithSelf('[data-file-upload-item-client-name]').val(this._metadata!.clientFilename);
        this._element.findWithSelf('[data-file-upload-item-open-file]').on('click', () => this.openFile());
        this._element.findWithSelf('[data-file-upload-item-download-file]').on('click', () => this.downloadFile());
        this._element.findWithSelf('[data-file-upload-item-remove-file]').on('click', () => this.remove());
    }

    private openFile(): void {
        this.assertIsUploaded();
        window.open(this._metadata!.url, '_blank')?.focus();
    }

    private downloadFile(): void {
        this.assertIsUploaded();
        const link = document.createElement('a');
        link.setAttribute('download', this._metadata!.clientFilename);
        link.href = this._metadata!.downloadUrl;
        link.click();
    }

    private remove(): void {
        if (this.onRemove !== null) {
            this.onRemove(this);
        }
    }

    public get base64Content(): string | null {
        return this._base64Content;
    }

    public set base64Content(value: string | null) {
        this._base64Content = value;
    }

    public get filename(): string | null {
        return this._filename;
    }

    public set filename(value: string | null) {
        this._filename = value;
    }

    public get onUpload(): ((file: FileUploadItem) => void) | null {
        return this._onUpload;
    }

    public set onUpload(value: ((file: FileUploadItem) => void) | null) {
        this._onUpload = value;
    }

    public get onRemove(): ((file: FileUploadItem) => void) | null {
        return this._onRemove;
    }

    public set onRemove(value: ((file: FileUploadItem) => void) | null) {
        this._onRemove = value;
    }

    public get metadata(): FileMetadata | null {
        return this._metadata;
    }

    public set metadata(value: FileMetadata | null) {
        this._metadata = value;
    }

    public get element(): JQuery {
        return this._element;
    }
}
