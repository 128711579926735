type OnScrollIntoView = (element: HTMLElement) => void;

class ScrollIntoViewObserver
{
    private callbacks: WeakMap<HTMLElement, OnScrollIntoView> = new WeakMap();
    private observer: IntersectionObserver | null = null;

    public observe(element: HTMLElement, onScrollIntoView: OnScrollIntoView): void {
        this.callbacks.set(element, onScrollIntoView);
        this.getObserver().observe(element);
    }

    public unobserve(element: HTMLElement): void {
        this.callbacks.delete(element);
        this.getObserver().unobserve(element);
    }

    private getObserver(): IntersectionObserver {
        if (this.observer === null) {
            this.observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const element = entry.target as HTMLElement;
                            const callback = this.callbacks.get(element);
                            if (typeof callback !== 'undefined') {
                                callback(element);
                            }
                        }
                    });
                },
                {
                    rootMargin: '0px 0px 0px 0px',
                    threshold: 0,
                },
            );
        }
        return this.observer;
    }
}

const scrollIntoViewObserver = new ScrollIntoViewObserver();

export default scrollIntoViewObserver;
