export default class Files
{
    public static async fileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                let base64File = reader.result;
                if (base64File === null) {
                    reject(new Error('Unexpected error.'));
                    return;
                }
                base64File = base64File.toString().replace(/^data:(.*,)?/, '');
                if (base64File.length % 4 > 0) {
                    base64File += '='.repeat(4 - (base64File.length % 4));
                }
                resolve(base64File);
            };
            reader.onerror = (e) => reject(e);
        });
    }
}
